import React from "react"
import {createColumnHelper} from "@tanstack/react-table"
import RowSelector from "@components/data-grid/column-header-components/row-selector/row-selector"
import ActionButton from "@components/tables/components/action-button"
import CheckboxField from "@components/fields/checkbox-field/checkbox-field"
import {TableActions} from "@components/tables/components/table-layout"

interface SelectionColumnOptions {
  checkboxFieldName?: string
  editButton?: boolean
  duplicateButton?: boolean
  actions?: TableActions
  modalTitle?: string
  active_permissions?: string[]
  hideDeleteModal?: boolean
  fetch_data_url?: string
  showSelectionColumnTitle?: boolean
  delete_button?: boolean
  add_button?: boolean
  hideSelectAll?: boolean
}

export const selectionColumn = <T extends {selection: string}>(
  rowAccessor: (row: T) => T["selection"],
  options: SelectionColumnOptions,
) => {
  const columnHelper = createColumnHelper<T>()
  const {hideDeleteModal = false} = options

  return columnHelper.accessor(rowAccessor, {
    id: "selection",
    footer: "selection",
    header: (props) => (
      <RowSelector
        table={props.table}
        showTitle={options.showSelectionColumnTitle}
        modalTitle={options.modalTitle}
        active_permissions={options.active_permissions}
        deleteUrl={options?.actions?.delete?.url}
        fetch_data_url={options.fetch_data_url}
        hideDeleteModal={hideDeleteModal}
        hideSelectAll={options.hideSelectAll}
      />
    ),
    enableResizing: false,
    size: 50,
    cell: ({row}) => (
      <div className="d-flex align-items-center gap-2 justify-items-center selection-column">
        {options.checkboxFieldName && (
          <CheckboxField
          name={options.checkboxFieldName}
          checked={row.getIsSelected() || options.active_permissions?.includes((row.original as T & {id: string}).id) || false}
          onChange={() => row.toggleSelected()}
        />
        )}
        {options.editButton && (
          <ActionButton
            iconSrc="/images/advantages-ico/edit.svg"
            onClick={() => (window.location.href = `${options?.actions?.edit?.url}${(row.original as T & {id: string}).id}`)}
          />
        )}
        {options.duplicateButton && (
          <ActionButton
            iconSrc="/images/svg/duplicate.svg"
            onClick={() => (window.location.href = `${options?.actions?.duplicate?.url}${(row.original as T & {id: string}).id}`)}
          />
        )}
        {options.add_button && (
          <ActionButton
            iconSrc="/images/svg/add.svg"
            onClick={() => (window.location.href = `${options?.actions?.add?.url}${(row.original as T & {id: string}).id}`)}
          />
        )}
        {options.delete_button && (
          <ActionButton
            iconSrc="/images/svg/delete.svg"
            onClick={() => {
              const id = (row.original as T & {id: string}).id
              if (isDeleteModal()) {
                openDeleteModal(id)
              } else {
                window.location.href = `${options?.actions?.delete?.url}${id}`
              }
            }}
          />
        )}
      </div>
    ),
  })
}

const isDeleteModal = () => !!document.querySelector(".c-modal-delete")

const openDeleteModal = (id: string) => {
  const modal = document.querySelector(".c-modal-delete")

  modal.dispatchEvent(new Event("open"))

  const confirmButton = modal.querySelector(".c-modal-delete__confirm")
  const urlBase = confirmButton.getAttribute("data-url")

  if (!confirmButton || !urlBase) return

  confirmButton.setAttribute("href", `${urlBase}${id}`)
}
