import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"

export type TListings = {
  selection: string
  id: string
  change_name: string
  change_date: string
  change_user: string
  url: string
}

export type THeaders = {
  selection: string
  id: string
  change_name: string
  change_date: string
  change_user: string
  url: string
}

const columnHelper = createColumnHelper<TListings>()

export const getColumns = (headers: THeaders) => {
  return [
    columnHelper.accessor((row) => row.change_name, {
      id: "change_name",
      footer: headers.change_name,
      header: ({column}) => <ColumnHeader title={headers.change_name} column={column} />,
      enableColumnFilter: true,
      cell: (props) => <a href={props.row.original.url}>{props.getValue()}</a>,
    }),
    columnHelper.accessor((row) => row.change_date, {
      id: "change_date",
      footer: headers.change_date,
      header: ({column}) => <ColumnHeader title={headers.change_date} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.inDateRange,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
    }),
    columnHelper.accessor((row) => row.change_user, {
      id: "change_user",
      filterFn: customFilterFns.multiSelect,
      footer: headers.change_user,
      header: ({column}) => <ColumnHeader title={headers.change_user} column={column} />,
      enableColumnFilter: true,
    }),
  ]
}
