import {SlimSelectElement} from "js/slim-select/slim-select"

export {}

type DemandCompany = {
  currency?: string
  type?: string
  parent?: string
  country?: string
  message?: string
}

document.querySelectorAll<HTMLElement>(".f-demands__company-fieldset").forEach((fieldset) => {
  const baseApiUrl = fieldset.getAttribute("data-api")

  const loaders = fieldset.querySelectorAll<SlimSelectElement>(".js-demand-company--loader select")
  const hiddenInput = fieldset.querySelector<HTMLInputElement>(".js-demand-company__id")

  // readonly inputs
  const currencyInput = fieldset.querySelector<HTMLInputElement>(".js-demand-company__currency input")
  const countryInput = fieldset.querySelector<HTMLInputElement>(".js-demand-company__country input")
  const typeInput = fieldset.querySelector<HTMLInputElement>(".js-demand-company__type input")
  const parentInput = fieldset.querySelector<HTMLInputElement>(".js-demand-company__parent input")
  const messageInput = fieldset.querySelector<HTMLTextAreaElement>(".js-demand-company__message textarea")

  const setSelected = (value: string) => {
    loaders.forEach((loader) => {
      if (!loader.classList.contains("--setter")) loader.slim.setSelected(value, false)
    })
  }

  const handleFetch = async (value: string) => {
    if (!baseApiUrl) return

    const response = await fetch(`${baseApiUrl}?id=${value}`)

    if (!response.ok) return null

    const data = (await response.json()) as DemandCompany

    console.log(data)

    if (currencyInput && data.currency) currencyInput.value = data.currency
    if (countryInput && data.country) countryInput.value = data.country
    if (typeInput && data.type) typeInput.value = data.type
    if (parentInput && data.parent) parentInput.value = data.parent
    if (messageInput && data.message) messageInput.value = data.message
  }

  loaders.forEach((loader) => {
    loader.slim.render.callbacks.afterChange = () => {
      const selected = loader.slim.getSelected().find(Boolean)
      loader.classList.add("--setter")
      setSelected(selected)
      if (hiddenInput) {
        hiddenInput.value = selected
        hiddenInput.dispatchEvent(new Event("change"))
      }
      handleFetch(selected)
      loader.classList.remove("--setter")
    }

    loader.slim.render.enable() // I guess update slim in order to enable added callback
  })
})
