import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"

import {createColumnHelper} from "@tanstack/react-table"

export type TPerson = {
  url: string
  firstName: string
  lastName: string
  role: string
  position: string
  phone: string
  email: string
  address: string
  city: string
  country: string
}

export type THeaders = {
  firstName: string
  lastName: string
  role: string
  position: string
  phone: string
  email: string
  address: string
  city: string
  country: string
}

const columnHelper = createColumnHelper<TPerson>()

export const getColumns = (headers: THeaders) => {
  return [
    columnHelper.accessor((row) => row.firstName, {
      id: "firstName",
      header: ({column}) => <ColumnHeader title={headers.firstName} column={column} />,
      cell: (props) => <a href={props.row.original.url}>{props.getValue()}</a>,
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: "lastName",
      header: ({column}) => <ColumnHeader title={headers.lastName} column={column} />,
      cell: (props) => <a href={props.row.original.url}>{props.getValue()}</a>,
    }),
    columnHelper.accessor((row) => row.role, {
      id: "role",
      header: ({column}) => <ColumnHeader title={headers.role} column={column} />,
    }),
    columnHelper.accessor((row) => row.position, {
      id: "position",
      header: ({column}) => <ColumnHeader title={headers.position} column={column} />,
    }),
    columnHelper.accessor((row) => row.phone, {
      id: "phone",
      header: ({column}) => <ColumnHeader title={headers.phone} column={column} />,
    }),
    columnHelper.accessor((row) => row.email, {
      id: "email",
      header: ({column}) => <ColumnHeader title={headers.email} column={column} />,
    }),
    columnHelper.accessor((row) => row.address, {
      id: "address",
      header: ({column}) => <ColumnHeader title={headers.address} column={column} />,
    }),
    columnHelper.accessor((row) => row.city, {
      id: "city",
      header: ({column}) => <ColumnHeader title={headers.city} column={column} />,
    }),
    columnHelper.accessor((row) => row.country, {
      id: "country",
      header: ({column}) => <ColumnHeader title={headers.country} column={column} />,
    }),
  ]
}
