import "css/style.css"
import {lazyLoading} from "./lazyload/lazyload-init"
import {ILazyLoadInstance} from "vanilla-lazyload"
import "@components/items/main-nav-item/main-nav-item"
import "@components/main-sidebar"
import "@components/base-form/base-form"
import "@components/tabs"
import "@components/fields/color-field/color-field"
import "@components/fields/text-field/text-field"
import "@components/fields/image-field/image-field"
import "@components/fields/switch-field/switch-field--watch-password"
import "@components/modals/ares-modal/ares-modal"
import "@components/tables"
import "@components/forms/company-form/company-form--info"
import "@components/forms/company-form/company-form--primary-bank-acc"
import "@components/forms/company-form/company-form--payments"
import "@components/forms/person-form/person-form--info"
import "@components/forms/person-form/person-form--addresses"
import "@components/forms/shopper-form/shopper-form--info"
import "@components/forms/shopper-form/shopper-form-address"
import "@components/forms/demands-form/demands-form--person"
import "@components/forms/demands-form/demands-form--company"
import "@components/forms/demands-form/demands-form--coverage"
import "@components/forms/demands-form/demands-form--address"
import "@components/forms/countries-currencies-form/countries-currencies-form--info"
import "@components/forms/pricelist-form/pricelist-form--special-prices"

declare global {
  interface Window {
    lazyloading?: ILazyLoadInstance
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, any>[]
  }
}
window.lazyloading = lazyLoading // add lazyloading to global window object// add lazyloading to global window object

async function loadFancybox() {
  if (document.querySelectorAll("[data-fancybox]").length) {
    await import("js/fancybox/fancybox")
  }
}
async function loadMain() {
  await import("js/main")
  loadFancybox()
}
loadMain()

async function loadOverlayScrollbars() {
  if (document.querySelectorAll(".u-scrollbar").length) {
    await import("js/overlayscrollbars")
  }
}
loadOverlayScrollbars()

async function loadFileInput() {
  if (document.querySelectorAll(".f-file").length) {
    await import("@components/fields/file-field/file-field")
  }
}
loadFileInput()

async function loadDateInput() {
  if (document.querySelectorAll(".f-date").length) {
    await import("@components/fields/date-field/date-field")
  }
}
loadDateInput()

async function loadSlimSelect() {
  if (document.querySelectorAll(".slim-select").length) {
    await import("js/slim-select/slim-select")
  }
}
loadSlimSelect()

async function loadAlerts() {
  if (document.querySelectorAll(".i-alert").length) {
    await import("@components/items/alert-item/alert-item")
  }
}
loadAlerts()

async function loadTippy() {
  if (document.querySelectorAll(".--tippy, [data-tippy-content]").length) {
    await import("js/tippy")
  }
}
loadTippy()

async function loadPasswordField() {
  if (document.querySelectorAll(".f-text.--password").length) {
    await import("@components/fields/text-field/text-field--password")
  }
}
loadPasswordField()

async function loadToggleableTextField() {
  if (document.querySelectorAll(".f-text.--toggleable, .f-date.--toggleable").length) {
    await import("@components/fields/text-field/text-field--toggleable")
  }
}
loadToggleableTextField()

async function loadDynamicFieldset() {
  if (document.querySelectorAll(".i-dynamic-fieldset").length) {
    await import("@components/items/dynamic-fieldset-item/dynamic-fieldset-item")
  }
}
loadDynamicFieldset()
