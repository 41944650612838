import {SlimSelectElement} from "js/slim-select/slim-select"
import {OptionOptional} from "slim-select/src/slim-select/store"

type Addresses = {
  items: OptionOptional[]
}

export {}

document.addEventListener("slimselectInit", () => {
  document.querySelectorAll(".f-demands__info-registered").forEach((fieldset) => {
    const personIdInput = fieldset.querySelector<HTMLInputElement>(".js-demand-person__id")
    const companyIdInput = fieldset.querySelector<HTMLInputElement>(".js-demand-company__id")

    const addressSelect = fieldset.querySelector<SlimSelectElement>(".js-demand-address select")
    const addressWrap = fieldset.querySelector(".js-demand-address")
    const apiBaseUrl = addressWrap.getAttribute("data-api")

    if (!addressSelect || !personIdInput || !companyIdInput) return

    const getParameters = () => {
      const personId = personIdInput.value
      const companyId = companyIdInput.value

      return {personId, companyId}
    }

    const getApiUrl = () => {
      if (!apiBaseUrl) return

      const apiUrl = new URL(`${window.location.origin}${apiBaseUrl}`)

      const parameters = getParameters()

      if (parameters.personId) apiUrl.searchParams.append("person", parameters.personId)
      if (parameters.companyId) apiUrl.searchParams.append("company", parameters.companyId)

      return `${apiUrl.pathname}${apiUrl.search}`
    }

    const handleFetch = async () => {
      const apiUrl = getApiUrl()
      if (!apiUrl) return

      const response = await fetch(apiUrl)

      if (!response.ok) return null

      const data = (await response.json()) as Addresses

      console.log(data)

      setAddress(data.items)
    }

    const setAddress = (data: OptionOptional[]) => {
      addressSelect.slim.setData(data)
      console.log(addressSelect.slim)
    }

    ;[personIdInput, companyIdInput].forEach((input) => {
      input.addEventListener("change", () => {
        addressWrap?.classList.add("--loading")
        handleFetch()
        addressWrap?.classList.remove("--loading")
      })
    })
  })
})
