import React from "react"
import {useDataTable} from "@components/data-grid/hooks/useDataTable"
import TableLayout from "@components/tables/components/table-layout"
import {getColNames} from "@components/tables/utils/getColNames"
import {DataTableProps} from "@components/data-grid/table.types"

export function DataTable<TData, TValue>({columns, data, buttons}: DataTableProps<TData, TValue>) {
  const {table, selectedData, setColumnOrder, setColumnFilters, columnFilters, filterResetKey, setFilterResetKey} = useDataTable({
    columns,
    data,
  })
  const columnConfig = [
    {
      name: "title",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "price",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "active",
      multiSelect: false,
      searchable: false,
      showIcon: true,
    },
    {
      name: "country_cz",
      multiSelect: false,
      searchable: false,
      showIcon: true,
    },
    {
      name: "country_sk",
      multiSelect: false,
      searchable: false,
      showIcon: true,
    },
    {
      name: "country_en",
      multiSelect: false,
      searchable: false,
      showIcon: true,
    },
    {
      name: "country_de",
      multiSelect: false,
      searchable: false,
      showIcon: true,
    },
  ]

  const columnNames = getColNames(columns)

  return (
    <TableLayout
      table={table}
      hasCollapsible={true}
      selectedData={selectedData}
      setColumnOrder={setColumnOrder}
      setColumnFilters={setColumnFilters}
      columnFilters={columnFilters}
      filterResetKey={filterResetKey}
      setFilterResetKey={setFilterResetKey}
      columnNames={columnNames}
      buttons={buttons}
      columnConfig={columnConfig}
      hideColumnReordering={true}
    />
  )
}
