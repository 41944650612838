document.querySelectorAll(".f-shopper__delivery-address").forEach((deliveryAddress) => {
  const sameSwitch = deliveryAddress.querySelector<HTMLInputElement>(".js-shopper-delivery-same-address input[type=checkbox]")
  const fieldset = deliveryAddress.querySelector<HTMLFieldSetElement>(".f-shopper__delivery-address-fieldset")

  if (!sameSwitch || !fieldset) return

  const handleSwitch = () => {
    fieldset.classList.toggle("d-none", sameSwitch.checked)
    fieldset.classList.toggle("d-flex", !sameSwitch.checked)

    const inputs = fieldset.querySelectorAll<HTMLInputElement>("input, select, textarea")

    inputs.forEach((input) => (input.required = !sameSwitch.checked))
  }

  handleSwitch()

  sameSwitch.addEventListener("change", handleSwitch)
})
