import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"
import SwitchVisual from "@components/tables/components/switch-visual"
import {selectionColumn} from "@components/data-grid/selection-column"
import {AggregateActions, TableActions} from "@components/tables/components/table-layout"

export type TListings = {
  selection: string
  title: string
  date: string
  num_of_users: string
  active: string
  id: string
  url: string
}

export type THeaders = {
  selection: string
  title: string
  date: string
  num_of_users: string
  active: string
  id: string
  url: string
}

const columnHelper = createColumnHelper<TListings>()

export const getColumns = (headers: THeaders, actions: TableActions, aggregate_actions: AggregateActions) => {
  return [
    selectionColumn<TListings>((row) => row.id, {
      checkboxFieldName: "select-checkbox",
      editButton: true,
      delete_button: true,
      actions,
      duplicateButton: true,
      aggregate_actions,
    }),
    columnHelper.accessor((row) => row.title, {
      id: "title",
      footer: headers.title,
      header: ({column}) => <ColumnHeader title={headers.title} column={column} />,
      enableColumnFilter: true,
      size: 200,
    }),
    columnHelper.accessor((row) => row.date, {
      id: "date",
      footer: "Datum",
      filterFn: customFilterFns.inDateRange,
      header: ({column}) => <ColumnHeader title={headers.date} column={column} />,
      enableColumnFilter: true,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
      size: 300,
    }),
    columnHelper.accessor((row) => row.num_of_users, {
      id: "num_of_users",
      footer: headers.num_of_users,
      header: ({column}) => <ColumnHeader title={headers.num_of_users} column={column} />,
      filterFn: customFilterFns.inNumberRange,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.active, {
      id: "active",
      footer: headers.active,
      header: ({column}) => <ColumnHeader title={headers.active} column={column} />,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      enableColumnFilter: true,
    }),
  ]
}
