import React from "react"
import {useDataTable} from "@components/data-grid/hooks/useDataTable"
import TableLayout from "@components/tables/components/table-layout"
import {getColNames} from "@components/tables/utils/getColNames"
import {DataTableProps} from "@components/data-grid/table.types"

export function DataTable<TData, TValue>({columns, data, buttons}: DataTableProps<TData, TValue>) {
  const {table, selectedData, setColumnOrder} = useDataTable({columns, data})

  const columnNames = getColNames(columns)

  const columnConfig = [
    {
      name: "id",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "type",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "status",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "source",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "cod",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "currency",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
  ]

  return (
    <TableLayout
      table={table}
      hasCollapsible={true}
      selectedData={selectedData}
      setColumnOrder={setColumnOrder}
      columnNames={columnNames}
      columnConfig={columnConfig}
      hideColumnReordering={true}
      buttons={buttons}
    />
  )
}
