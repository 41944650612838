type Subject = {
  reg_num: string // ico
  vat_num: string // dic
  city: string
  country: string
  name: string
  street: string
  zipcode: string
}

class AresClient {
  async searchByIco(ico: string) {
    const response = await fetch(`https://ares-api.brilo.work/api/v1/searchByICO?number=${ico}`)

    if (!response.ok) return null

    const data = (await response.json()) as Subject | []

    if (Array.isArray(data)) return null

    return data
  }
}

export const aresClient = new AresClient()
