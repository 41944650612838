import {Column, HeaderGroup as TanHeaderGroup} from "@tanstack/react-table"
import {useEffect, useState} from "react"

interface HeaderGroup<TData> extends TanHeaderGroup<TData> {
  column?: Column<TData, unknown>
}

export const NumberRangeFilter = <TData,>({header, filterResetKey}: {header: HeaderGroup<TData>; filterResetKey: number}) => {
  const [minValue, setMinValue] = useState<string | null>(null)
  const [maxValue, setMaxValue] = useState<string | null>(null)

  useEffect(() => {
    setMinValue(null)
    setMaxValue(null)
  }, [filterResetKey])

  return (
    <div className="number-range-filter">
      <input
        type="number"
        className="number-range-filter__min"
        placeholder="Od"
        value={minValue ?? ""}
        onChange={(e) => {
          let min = e.target.value || null
          min = min !== null ? Math.max(Number(min), 0).toString() : null
          setMinValue(min)
          const filterValue = header.column?.getFilterValue() as (string | undefined)[]
          const max = filterValue ? filterValue[1] : null
          header.column?.setFilterValue([min, max])
        }}
      />
      <input
        type="number"
        placeholder="Do"
        className="number-range-filter__max"
        value={maxValue ?? ""}
        onChange={(e) => {
          let max = e.target.value || null
          max = max !== null ? Math.max(Number(max), 0).toString() : null
          setMaxValue(max)
          const filterValue = header.column?.getFilterValue() as (string | undefined)[]
          const min = filterValue ? filterValue[0] : null
          header.column?.setFilterValue([min, max])
        }}
      />
    </div>
  )
}
