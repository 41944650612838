import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"
// import SwitchVisual from "@components/tables/components/switch-visual"
import {selectionColumn} from "@components/data-grid/selection-column"
import {TableActions} from "@components/tables/components/table-layout"

export type TListings = {
  selection: string
  name: string
  price: string
  code: string
  in_stock: string
  id: string
}

export type THeaders = {
  selection: string
  name: string
  price: string
  code: string
  in_stock: string
  id: string
}

const columnHelper = createColumnHelper<TListings>()

export const getColumns = (headers: THeaders, actions: TableActions) => {
  return [
    selectionColumn<TListings>((row) => row.id, {
      checkboxFieldName: "select-checkbox",
      editButton: true,
      duplicateButton: false,
      actions,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      footer: headers.name,
      header: ({column}) => <ColumnHeader title={headers.name} column={column} />,
      enableColumnFilter: true,
      size: 200,
    }),

    columnHelper.accessor((row) => row.price, {
      id: "price",
      footer: headers.price,
      header: ({column}) => <ColumnHeader title={headers.price} column={column} />,
      filterFn: customFilterFns.inNumberRange,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.code, {
      id: "code",
      footer: headers.code,
      header: ({column}) => <ColumnHeader title={headers.code} column={column} />,
      enableColumnFilter: true,
      size: 200,
    }),
    columnHelper.accessor((row) => row.in_stock, {
      id: "in_stock",
      footer: headers.in_stock,
      header: ({column}) => <ColumnHeader title={headers.in_stock} column={column} />,
      enableColumnFilter: true,
      size: 180,
    }),
  ]
}
