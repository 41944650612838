import SlimSelect, {Config} from "slim-select"

export const applySlimSelectStyles = (slimSelectInstance: SlimSelect) => {
  slimSelectInstance.render.main.arrow.main.setAttribute("width", "24")
  slimSelectInstance.render.main.arrow.main.setAttribute("height", "24")
  slimSelectInstance.render.main.arrow.main.setAttribute("viewBox", "0 0 24 24")
  slimSelectInstance.render.main.arrow.main.setAttribute("fill", "none")

  slimSelectInstance.render.main.arrow.path.setAttribute("d", "M4.5 7.5L12 15L19.5 7.5")
  slimSelectInstance.render.main.arrow.path.setAttribute("stroke", "#15324F")
  slimSelectInstance.render.main.arrow.path.setAttribute("stroke-width", "2")

  slimSelectInstance.render.classes.arrowClose = "M4.5 7.5L12 15L19.5 7.5"
  slimSelectInstance.render.classes.arrowOpen = "M4.5 15L12 7.5L19.5 15"
}

export const initSelect = (select: HTMLSelectElement) => {
  const hasSearch = select.hasAttribute("has-search")

  select.classList.remove("--native")

  const settings: Config = {
    select: select,
    settings: {
      showSearch: hasSearch,
      searchPlaceholder: select.getAttribute("search-placeholder") ?? "",
      searchText: select.getAttribute("search-no-results") ?? "",
      hideSelected: true,
    },
  }

  const singleSlim = new SlimSelect(settings)
  applySlimSelectStyles(singleSlim)
}

const init = () => {
  const selects = document.querySelectorAll(".f-single-select:not(.--initialized)")

  selects.forEach((select) => {
    const selectInput = select.querySelector<HTMLSelectElement>(".f-single-select__select.slim-select")

    if (!selectInput) return
    initSelect(selectInput)
    select.classList.add("--initialized")
  })
}

init()
document.addEventListener("singleSelectReinit", init)
