import React from "react"
import {useDataTable} from "@components/data-grid/hooks/useDataTable"
import TableLayout from "@components/tables/components/table-layout"
import {getColNames} from "@components/tables/utils/getColNames"
import {DataTableProps} from "@components/data-grid/table.types"

export function DataTable<TData, TValue>({columns, data, buttons}: DataTableProps<TData, TValue>) {
  const {table, selectedData, setColumnOrder, setColumnFilters, columnFilters, filterResetKey, setFilterResetKey} = useDataTable({
    columns,
    data,
  })
  const columnNames = getColNames(columns)

  const columnConfig = [
    {
      name: "company_name",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "slogan",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "country",
      multiSelect: true,
      searchable: false,
      showIcon: true,
    },
    {
      name: "street",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "city",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "postal_code",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "ic_number",
      multiSelect: true,
      searchable: true,
      showIcon: true,
    },
    {
      name: "dic_number",
      multiSelect: true,
      searchable: true,
      showIcon: true,
    },
    {
      name: "email",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "phone_number",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "company_type",
      multiSelect: true,
      searchable: false,
      showIcon: true,
    },
    {
      name: "pricing",
      multiSelect: true,
      searchable: false,
      showIcon: true,
    },
    {
      name: "parent_company",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "currency",
      multiSelect: false,
      searchable: false,
      showIcon: true,
    },
    {
      name: "payment_type",
      multiSelect: true,
      searchable: false,
      showIcon: true,
    },
    {
      name: "marketing_field",
      multiSelect: false,
      searchable: false,
      showIcon: true,
    },
    {
      name: "notifications",
      multiSelect: false,
      searchable: false,
      showIcon: true,
    },
    {
      name: "id",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
  ]

  return (
    <TableLayout
      table={table}
      hasCollapsible={true}
      filterResetKey={filterResetKey}
      setFilterResetKey={setFilterResetKey}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      selectedData={selectedData}
      setColumnOrder={setColumnOrder}
      columnNames={columnNames}
      buttons={buttons}
      columnConfig={columnConfig}
    />
  )
}
