import {useTableState} from "./useTableState"
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getExpandedRowModel,
} from "@tanstack/react-table"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"

interface UseDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

interface SubRow {
  subRows?: SubRow[]
}

export function useDataTable<TData, TValue>({columns, data}: UseDataTableProps<TData, TValue>) {
  const {
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters,
    rowSelection,
    setRowSelection,
    selectedData,
    columnOrder,
    setColumnOrder,
    columnVisibility,
    setColumnVisibility,
    expanded,
    setExpanded,
    filterResetKey,
    setFilterResetKey,
  } = useTableState(data)

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getSubRows: (row: TData) => (row as TData & SubRow).subRows as TData[] | undefined,
    filterFns: customFilterFns,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    enableExpanding: true,
    enableFilters: true,
    defaultColumn: {
      // minSize: 150,
      size: 250,
    },
    state: {
      sorting,
      rowSelection,
      columnFilters,
      columnOrder,
      columnVisibility,
      expanded,
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
  })

  return {
    table,
    selectedData,
    setSorting,
    setColumnFilters,
    setRowSelection,
    setColumnOrder,
    setColumnVisibility,
    columnFilters,
    filterResetKey,
    setFilterResetKey,
  }
}
