import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"

export type TPerson = {
  url: string
  order_number: string
  date: string
  order_category: string
  order_type: string
  status: string
  price: string
  currency: string
  payment: string
  cod: string
}

export type THeaders = {
  url: string
  order_number: string
  date: string
  order_category: string
  order_type: string
  status: string
  price: string
  currency: string
  payment: string
  cod: string
}

const columnHelper = createColumnHelper<TPerson>()

export const getColumns = (headers: THeaders) => {
  return [
    columnHelper.accessor((row) => row.order_number, {
      id: "order_number",
      footer: headers.order_number,
      header: ({column}) => <ColumnHeader title={headers.order_number} column={column} />,
    }),
    columnHelper.accessor((row) => row.date, {
      id: "date",
      footer: headers.date,
      header: ({column}) => <ColumnHeader title={headers.date} column={column} />,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
    }),
    columnHelper.accessor((row) => row.order_category, {
      id: "order_category",
      header: ({column}) => <ColumnHeader title={headers.order_category} column={column} />,
      footer: headers.order_category,
    }),
    columnHelper.accessor((row) => row.order_type, {
      id: "order_type",
      header: ({column}) => <ColumnHeader title={headers.order_type} column={column} />,
      footer: headers.order_type,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      header: ({column}) => <ColumnHeader title={headers.status} column={column} />,
      footer: headers.status,
    }),
    columnHelper.accessor((row) => row.price, {
      id: "price",
      header: ({column}) => <ColumnHeader title={headers.price} column={column} />,
      footer: headers.price,
    }),
    columnHelper.accessor((row) => row.currency, {
      id: "currency",
      header: ({column}) => <ColumnHeader title={headers.currency} column={column} />,
      footer: headers.currency,
    }),
    columnHelper.accessor((row) => row.payment, {
      id: "payment",
      header: ({column}) => <ColumnHeader title={headers.payment} column={column} />,
      footer: headers.payment,
    }),
    columnHelper.accessor((row) => row.cod, {
      id: "cod",
      header: ({column}) => <ColumnHeader title={headers.cod} column={column} />,
      footer: headers.cod,
    }),
  ]
}
