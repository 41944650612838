import SlimSelect from "slim-select"
import {Option} from "slim-select/dist/store"
import {applySlimSelectStyles} from "@components/fields/single-select-field/single-select-field"
import {applySlimSelectStylesMulti} from "@components/fields/multi-select-field/multi-select-field"

interface SlimInstance extends HTMLSelectElement {
  slim: SlimSelect
}

declare global {
  interface Window {
    updateOptionsAfterDeletion: (deletedValue: string, deleteUrl: string, optionId: string) => void
  }
}

function updateSingleSelectOptions(data, primaryBankAccountSlimSelect: SlimSelect) {
  const primarySelected = primaryBankAccountSlimSelect.getSelected()[0]

  const selectedOptions = data.filter((option) => option.selected)

  if (selectedOptions.length === 0) {
    primaryBankAccountSlimSelect.setData([])
    primaryBankAccountSlimSelect.disable()
  } else {
    primaryBankAccountSlimSelect.enable()
    primaryBankAccountSlimSelect.setData(selectedOptions.map((option) => ({...option, html: option.text})))
  }

  if (primarySelected) {
    primaryBankAccountSlimSelect.setSelected(primarySelected)
  }
}

function generateDeleteButtonHtml(optionValue: string, deleteUrl: string, optionId: string): string {
  return `<button class="f-multi-select__delete" data-delete-value="${optionValue}" onclick="event.stopPropagation(); window.updateOptionsAfterDeletion('${optionValue}', '${deleteUrl}','${optionId}');" style="background: none; border: none; padding: 0; cursor: pointer;">
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10Z" fill="#51B687"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z" fill="#51B687"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V5H17V4C17 3.20435 16.6839 2.44129 16.1213 1.87868C15.5587 1.31607 14.7956 1 14 1H10C9.20435 1 8.44129 1.31607 7.87868 1.87868C7.31607 2.44129 7 3.20435 7 4V5H9V4C9 3.73478 9.10536 3.48043 9.29289 3.29289ZM5 5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4V20C4 20.7957 4.31607 21.5587 4.87868 22.1213C5.44129 22.6839 6.20435 23 7 23H17C17.7957 23 18.5587 22.6839 19.1213 22.1213C19.6839 21.5587 20 20.7957 20 20V7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H19C19.5523 5 20 5.44772 20 6V7H18V20C18 20.2652 17.8946 20.5196 17.7071 20.7071C17.5196 20.8946 17.2652 21 17 21H7C6.73478 21 6.48043 20.8946 6.29289 20.7071C6.10536 20.5196 6 20.2652 6 20V7H4V6C4 5.44772 4.44772 5 5 5Z" fill="#51B687"/>
  <path d="M19 5H17H15H9H7H5C4.44772 5 4 5.44772 4 6V7H6H18H20V6C20 5.44772 19.5523 5 19 5Z" fill="#51B687"/>
  </svg>
  </button>`
}

window.updateOptionsAfterDeletion = function (deletedValue: string, deleteUrl: string, optionId: string) {
  // will be 'filled in' later, as it depends on companyInfoBankAccountSlimSelect being defined
}

function getInitialOptionsData(selectElement: HTMLSelectElement, includeDeleteButton = true) {
  return Array.from(selectElement.options).map((option) => ({
    text: option.text,
    value: option.value,
    selected: option.selected,
    html: `<div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">${option.text}</div>`,
  }))
}

function toggleRequiredAttributeBasedOnModalState(modalElement: HTMLElement) {
  const isOpen = modalElement?.classList.contains("--open")
  const bankAccountNameInput = document.querySelector(".js-company-info-modal-bank-account-name input") as HTMLInputElement
  const bankAccountNumberInput = document.querySelector(".js-company-info-modal-bank-account-number input") as HTMLInputElement
  const bankAccountNameLabel = document.querySelector(".js-company-info-modal-bank-account-name label") as HTMLLabelElement
  const bankAccountNumberLabel = document.querySelector(".js-company-info-modal-bank-account-number label") as HTMLLabelElement

  if (isOpen) {
    bankAccountNameInput.required = true
    bankAccountNumberInput.required = true
    bankAccountNameLabel.classList.add("--required")
    bankAccountNumberLabel.classList.add("--required")
  } else {
    bankAccountNameInput.required = false
    bankAccountNumberInput.required = false
    bankAccountNameLabel.classList.remove("--required")
    bankAccountNumberLabel.classList.remove("--required")
  }
}

function initializeFormElements() {
  const singleSelectElement = document.querySelector<SlimInstance>(".js-company-info-bank-account-primary select")
  const multiSelectElement = document.querySelector<SlimInstance>(".js-company-info-bank-account select")
  const modalElement = document.getElementById("bank-account-field")
  if (!singleSelectElement || !multiSelectElement || !modalElement) {
    return
  }
  const add_url = modalElement.hasAttribute("data-add-url") ? modalElement.getAttribute("data-add-url") : ""
  const deleteUrl = multiSelectElement.hasAttribute("data-delete-url") ? multiSelectElement.getAttribute("data-delete-url") : ""

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.attributeName === "class") {
        toggleRequiredAttributeBasedOnModalState(modalElement)
      }
    })
  })

  observer.observe(modalElement, {
    attributes: true,
  })
  toggleRequiredAttributeBasedOnModalState(modalElement)

  const initialMultiSelectData = getInitialOptionsData(multiSelectElement)
  const companyInfoBankAccountSlimSelect = multiSelectElement.slim

  const primaryBankAccountSlimSelect = singleSelectElement.slim

  function updateOptionsWithHtmlAndDeleteButton() {
    const allCompanyOptions = companyInfoBankAccountSlimSelect.getData()

    const updatedOptions = allCompanyOptions.map((option: Option) => {
      const deleteButtonHtml = generateDeleteButtonHtml(option.value, deleteUrl, option.value)
      return {
        ...option,
        html: `<div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">${option.text}${deleteButtonHtml}</div>`,
      }
    })

    companyInfoBankAccountSlimSelect.setData(updatedOptions)
  }

  applySlimSelectStyles(primaryBankAccountSlimSelect)
  applySlimSelectStylesMulti(companyInfoBankAccountSlimSelect)

  updateOptionsWithHtmlAndDeleteButton()

  updateSingleSelectOptions(initialMultiSelectData, primaryBankAccountSlimSelect)

  window.updateOptionsAfterDeletion = function (deletedValue: string, deleteUrl: string, optionId: string) {
    const originalData = companyInfoBankAccountSlimSelect.getData() as Option[]
    const updatedData = originalData.filter((option) => String(option.value) !== deletedValue)

    console.log("Value:", deletedValue)
    console.log("Original data:", originalData)
    console.log("Updated data", updatedData)

    companyInfoBankAccountSlimSelect.setData(updatedData)
    updateSingleSelectOptions(updatedData, primaryBankAccountSlimSelect)
    updateSingleSelect(companyInfoBankAccountSlimSelect, primaryBankAccountSlimSelect)

    console.log(deleteUrl, optionId)

    fetch(`${deleteUrl}?id=${optionId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        console.log("Delete operation successful")
      })
      .catch((error) => console.error("Delete error", error))
  }

  const saveButton = document.querySelector(".js-company-info-modal-bank-account-save") as HTMLButtonElement
  const bankAccountNameInput = document.querySelector(".js-company-info-modal-bank-account-name input") as HTMLInputElement

  const bankAccountNumberInput = document.querySelector(".js-company-info-modal-bank-account-number input") as HTMLInputElement

  async function addBankAccount(fetchUrl: string): Promise<string> {
    return fetch(fetchUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then((data) => {
        return data.id
      })
      .catch((error) => {
        console.error("Error:", error)
        throw error
      })
  }

  saveButton.addEventListener("click", async () => {
    const bankAccountName = bankAccountNameInput.value.trim()
    const bankAccountNumber = bankAccountNumberInput.value.trim()

    const fetchUrl = `${add_url}?name=${encodeURIComponent(bankAccountName)}&number=${encodeURIComponent(bankAccountNumber)}`

    try {
      const returned_id = await addBankAccount(fetchUrl)

      const deleteButtonHtml = generateDeleteButtonHtml(returned_id, deleteUrl, returned_id)

      const newOptionWithDelete = {
        text: bankAccountName,
        value: returned_id,
        html: `<div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">${bankAccountName}${deleteButtonHtml}</div>`,
        selected: true,
        id: returned_id,
      }

      const newOptionWithoutDelete = {
        text: bankAccountName,
        value: returned_id,
        html: `<div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">${bankAccountName}</div>`,
        selected: true,
        id: returned_id,
      }

      companyInfoBankAccountSlimSelect.addOption(newOptionWithDelete)
      primaryBankAccountSlimSelect.addOption(newOptionWithoutDelete)

      bankAccountNameInput.value = ""
      bankAccountNumberInput.value = ""
    } catch (error) {
      console.error("Error:", error)
    }
  })

  if (multiSelectElement) {
    multiSelectElement.addEventListener("change", () => {
      updateSingleSelectOptions(companyInfoBankAccountSlimSelect.getData(), primaryBankAccountSlimSelect)
    })
  }
}

function updateSingleSelect(companyInfoBankAccountSlimSelect: SlimSelect, primaryBankAccountSlimSelect: SlimSelect) {
  const selectedOptions = companyInfoBankAccountSlimSelect
    .getData()
    .filter((option): option is Option => "value" in option && option.selected)

  if (selectedOptions.length === 0) {
    primaryBankAccountSlimSelect.disable()
    primaryBankAccountSlimSelect.setData([])
    return
  } else {
    primaryBankAccountSlimSelect.enable()
  }

  const firstSelectedOption = selectedOptions.length > 0 ? selectedOptions[0] : null
  if (firstSelectedOption) {
    primaryBankAccountSlimSelect.setSelected(firstSelectedOption.value, true)
  } else {
    const allOptions = companyInfoBankAccountSlimSelect.getData().filter((option): option is Option => "value" in option)
    if (allOptions.length > 0) {
      const firstOptionValue = allOptions[0].value
      primaryBankAccountSlimSelect.setSelected(firstOptionValue, true)
    }
  }
}

initializeFormElements()
