import React from "react"
import {Button} from "@components/button/button"

interface ActionButtonProps {
  iconSrc: string
  onClick: () => void
  title?: string
  iconOnly?: boolean
}

const ActionButton: React.FC<ActionButtonProps> = ({iconSrc, onClick, title, iconOnly = true}) => (
  <Button
    tag="button"
    type="button"
    iconOnly={iconOnly}
    variant="blank"
    iconBefore={true}
    size="xxs"
    title={title}
    icon={{src: iconSrc}}
    onClick={onClick}
  />
)

export default ActionButton
