export {}

document.querySelectorAll(".f-pricelist__special-prices").forEach((specialPrices) => {
  const productSelectElement = specialPrices.querySelector<HTMLSelectElement>(".js-pricelist-product-select select")
  const addProductElement = specialPrices.querySelector<HTMLElement>(".js-pricelist-add-product")
  const productTemplate = specialPrices.querySelector<HTMLTemplateElement>(".f-pricelist__template")

  const initRemove = () => {
    specialPrices.querySelectorAll(".f-pricelist__product-remove").forEach((remove) => {
      if (remove.classList.contains("--init")) return

      remove.addEventListener("click", () => remove.closest(".f-pricelist__product-body")?.remove())
      remove.classList.add("--init")
    })
  }

  addProductElement?.addEventListener("click", (e) => {
    e.preventDefault()
    const productId = productSelectElement.selectedOptions[0].id ?? ""
    const title = productSelectElement.selectedOptions[0].dataset.title ?? ""
    if (!productId) return

    if (specialPrices.querySelectorAll<HTMLElement>(`.f-pricelist__product-body[data-id=${productId}]`).length > 0) return

    if (!productTemplate) return

    const clone = productTemplate.content.cloneNode(true) as DocumentFragment
    const templateTitle = clone.querySelector<HTMLElement>(".f-pricelist__product-name")
    if (templateTitle) {
      templateTitle.textContent = title
    }

    const cloneElement = clone.querySelector<HTMLElement>(".f-pricelist__product-body")
    if (cloneElement) {
      cloneElement.setAttribute("data-id", productId)
    }

    const inputs = clone.querySelectorAll<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>("input, select, textarea")

    inputs.forEach((input) => {
      const name = input.getAttribute("name")
      if (!name) return

      const newName = name.replace(/\[id\]/, `[${productId}]`)
      input.setAttribute("name", newName)
    })

    specialPrices.appendChild(clone)

    document.dispatchEvent(new Event("inputsReinit"))
    document.dispatchEvent(new Event("singleSelectReinit"))
    document.dispatchEvent(new Event("dynamicFieldsetInit"))
    initRemove()
  })

  initRemove()
})
