import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"

export type TDocuments = {
  url: string
  document_type: string
  document_number: string
  linked_order: string
  date: string
  source: string
  amount: string
  currency: string
  payment: string
  status: string
  last_update: string
  worker: string
  name: string
}

export type THeaders = {
  url: string
  document_type: string
  document_number: string
  linked_order: string
  date: string
  source: string
  amount: string
  currency: string
  payment: string
  status: string
  last_update: string
  worker: string
  name: string
}

const columnHelper = createColumnHelper<TDocuments>()

export const getColumns = (headers: THeaders) => {
  return [
    columnHelper.accessor((row) => row.document_type, {
      id: "document_type",
      footer: headers.document_type,
      header: ({column}) => <ColumnHeader title={headers.document_type} column={column} />,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.document_number, {
      id: "document_number",
      header: ({column}) => <ColumnHeader title={headers.document_number} column={column} />,
      enableColumnFilter: true,
      footer: headers.document_number,
    }),
    columnHelper.accessor((row) => row.linked_order, {
      id: "linked_order",
      header: ({column}) => <ColumnHeader title={headers.linked_order} column={column} />,
      enableColumnFilter: true,
      footer: headers.linked_order,
      cell: (props) => <a href={props.row.original.url}>{props.getValue()}</a>,
    }),
    columnHelper.accessor((row) => row.date, {
      id: "date",
      footer: headers.date,
      header: ({column}) => <ColumnHeader title={headers.date} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.inDateRange,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
    }),
    columnHelper.accessor((row) => row.source, {
      id: "source",
      header: ({column}) => <ColumnHeader title={headers.source} column={column} />,
      enableColumnFilter: true,
      footer: headers.source,
    }),
    columnHelper.accessor((row) => row.amount, {
      id: "amount",
      header: ({column}) => <ColumnHeader title={headers.amount} column={column} />,
      enableColumnFilter: true,
      footer: headers.amount,
      filterFn: customFilterFns.inNumberRange,
    }),
    columnHelper.accessor((row) => row.currency, {
      id: "currency",
      header: ({column}) => <ColumnHeader title={headers.currency} column={column} />,
      enableColumnFilter: true,
      footer: headers.currency,
    }),
    columnHelper.accessor((row) => row.payment, {
      id: "payment",
      header: ({column}) => <ColumnHeader title={headers.payment} column={column} />,
      enableColumnFilter: true,
      footer: headers.payment,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      header: ({column}) => <ColumnHeader title={headers.status} column={column} />,
      enableColumnFilter: true,
      footer: headers.status,
    }),
    columnHelper.accessor((row) => row.last_update, {
      id: "last_update",
      footer: headers.last_update,
      header: ({column}) => <ColumnHeader title={headers.last_update} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.inDateRange,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
    }),
    columnHelper.accessor((row) => row.worker, {
      id: "worker",
      header: ({column}) => <ColumnHeader title={headers.worker} column={column} />,
      enableColumnFilter: true,
      footer: headers.worker,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: ({column}) => <ColumnHeader title={headers.name} column={column} />,
      enableColumnFilter: true,
      footer: headers.name,
    }),
  ]
}
