import {Button} from "@components/button/button"
import React from "react"
import {ReactSVG} from "react-svg"

interface DeleteModalProps {
  id?: string
  size?: "xl" | "sm"
  extraClass?: string
  open?: boolean
  title?: string
  setIsModalOpen?: (value: boolean) => void
  selectedRowIds?: string[]
  deleteUrl?: string
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  id,
  size,
  extraClass,
  open,
  setIsModalOpen,
  title,
  selectedRowIds,
  deleteUrl,
}) => {
  const sizeClass = size ? `--size-${size}` : ""
  const openClass = open ? "--open" : "--hidden"
  const className = `c-modal ${sizeClass} ${extraClass ?? ""} ${openClass}`

  return (
    <div id={id} className={className}>
      <div className="c-modal__dialog">
        <div className="c-modal__content">
          <div className="c-modal__inner d-flex flex-column">
            <div className="c-modal__body">
              <ReactSVG src="/images/svg/info.svg" className="c-modal__icon" />
              <header className="c-modal__header">
                <h2 className="c-modal__heading u-fw-600">{title}</h2>
              </header>
            </div>
            <div className="c-modal__button-container">
              <Button
                title="Zrušit"
                tag="button"
                variant="outline"
                type="button"
                onClick={() => setIsModalOpen && setIsModalOpen(false)}
              />
              <Button
                title="Ano, odstranit"
                tag="button"
                type="button"
                iconBefore={true}
                icon={{
                  src: "/images/svg/delete.svg",
                }}
                onClick={() => {
                  const idsParam = selectedRowIds?.map((id) => `ids[]=${id}`).join("&")
                  window.location.href = `${deleteUrl}?${idsParam}`
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
