import React from "react"
import ReactDOM from "react-dom/client"
import {DataTable} from "./listings-datatable"
import {TListings, THeaders, getColumns} from "./listings-columns"
import {ButtonsActions, TableActions} from "@components/tables/components/table-layout"

export {}

const tables = document.querySelectorAll<HTMLElement>(".products-listings-table")

type Table = {
  data: TListings[]
  headers: THeaders
  buttons: ButtonsActions
  actions: TableActions
}

tables.forEach((tableElement) => {
  const table = JSON.parse(tableElement.dataset.table ?? "null") as Table | null
  if (!table) return

  ReactDOM.createRoot(tableElement).render(
    <React.StrictMode>
      <DataTable data={table.data} columns={getColumns(table.headers, table.actions)} buttons={table.buttons} />
    </React.StrictMode>,
  )
})
