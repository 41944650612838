import React, {useState, useEffect} from "react"
import {ReactSVG} from "react-svg"

export const switchVisualStateUpdaters = {}

interface SwitchVisualProps {
  value: string
  rowId?: string
}

const SwitchVisual = ({value, rowId}: SwitchVisualProps) => {
  const [isActive, setIsActive] = useState(value === "true")

  useEffect(() => {
    if (rowId) {
      switchVisualStateUpdaters[rowId] = setIsActive
      return () => {
        delete switchVisualStateUpdaters[rowId]
      }
    }
  }, [rowId])

  return (
    <div className={`column-check ${isActive ? "active" : "inactive"}`}>
      <input type="checkbox" checked={isActive} disabled className="switch-visual__hidden" id={`switch-visual__${rowId}`} />
      <div className="column-check__icon-container">
        {isActive ? (
          <ReactSVG src="/images/svg/check.svg" className="column-check__checkmark" />
        ) : (
          <ReactSVG src="/images/svg/xmark.svg" className="column-check__xmark" />
        )}
      </div>
    </div>
  )
}

export default SwitchVisual
