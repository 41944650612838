import {errorField} from "@components/fields/error-field/error-field"

export {}

type InputTypes = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement

const events = ["blur", "input", "change"]
const inputsSelector = "input:not(.--validating), select:not(.--validating), textarea:not(.--validating)"
const inputsWrappersSelector = ".f-text, .f-single-select, .f-checkbox, .f-file, .f-textarea, .f-switch, .f-image"

const validateInput = (input: InputTypes) => {
  if (input.disabled) return

  const isValid = input.checkValidity()
  const message = getValidationMessage(input)
  const inputWrapper = input.closest(inputsWrappersSelector)

  const panel = input.closest<HTMLElement>(".c-tabs__panel, .c-languages__panel")
  const tabGroup = input.closest<HTMLElement>(".c-tabs, .c-languages")
  const navItem = tabGroup?.querySelector(
    `.c-tabs__nav-item[data-panel-target="${panel?.dataset.panelId}"], .c-languages__nav-item[data-panel-target="${panel?.dataset.panelId}"]`,
  )

  if (!isValid) navItem?.classList.add("--invalid")

  if (panel) {
    const allInputsInPanel = panel.querySelectorAll<InputTypes>(inputsSelector.replaceAll(":not(.--validating)", ""))
    const allInputsValidInPanel = Array.from(allInputsInPanel).every((input) => input.checkValidity())

    if (allInputsValidInPanel) {
      navItem?.classList.remove("--invalid")
    }
  }

  inputWrapper?.classList.toggle("--invalid", !isValid)
  const error = new errorField(inputWrapper?.querySelector(".f-error") ?? null)
  error.toggle(!isValid)
  error.setTitle(message)
  isValid ? input.setAttribute("aria-invalid", "false") : input.setAttribute("aria-invalid", "true")
}

const handleInputEvents = (input: InputTypes) => {
  input.classList.add("--validating")

  events.forEach((event) => {
    input.addEventListener(event, () => {
      validateInput(input)
    })
  })
}

const getValidationMessage = (input: InputTypes) => {
  const {validity, dataset} = input
  let message = input.validationMessage

  // This could be expanded for any validity type and change message.
  // Since I doubt this will be ever used, I will just leave it only for patternMismatch.
  // But feel free to expand this.
  if (validity.patternMismatch && dataset.validityPatternMismatch) {
    message = dataset.validityPatternMismatch
  }

  if (validity.valueMissing && dataset.validityValueMissing) {
    message = dataset.validityValueMissing
  }

  return message
}

const removeDupliteInputs = (inputs: InputTypes[]) => {
  const names = inputs.map((input) => input.name)
  const uniqueNames = names.filter((name, index) => names.indexOf(name) === index)

  const uniqueInputs = uniqueNames.map((name) => inputs.find((input) => input.name === name) as InputTypes)

  return uniqueInputs
}

const handleFormSubmit = (form: HTMLFormElement) => {
  form.addEventListener("submit", (event: Event) => {
    form.querySelectorAll(".c-tabs__nav-item.--invalid").forEach((navItem) => {
      navItem.classList.remove("--invalid")
    })

    const inputs = form.querySelectorAll<InputTypes>(inputsSelector.replaceAll(":not(.--validating)", ""))
    const isValid = form.checkValidity()

    const filteredInputs = removeDupliteInputs(Array.from(inputs))

    filteredInputs.forEach((input) => validateInput(input))

    if (!isValid) {
      event.preventDefault()
    }
  })
}

const initForms = () => {
  const formsSelector = "form.--validate:not(.--initialized)"
  const forms = document.querySelectorAll<HTMLFormElement>(formsSelector)

  forms.forEach((form) => {
    /**
     * Setting noValidate turns off the native client-side validation,
     * freeing us up to do whatever we want. Turning off validation with JavaScript ensures
     * the default validation still runs if JavaScript never executes for whatever reason.
     * It also prevents showing our invalid style preemptively.
     */
    form.noValidate = true

    const inputs = form.querySelectorAll<InputTypes>(inputsSelector)
    inputs.forEach(handleInputEvents)

    handleFormSubmit(form)

    form.classList.add("--initialized")

    form.addEventListener("inputsReinit", () => {
      const inputs = form.querySelectorAll<InputTypes>(inputsSelector)
      inputs.forEach(handleInputEvents)
    })
  })
}

initForms()
document.addEventListener("baseformReinit", initForms)
