// dropdown-filter.tsx
import React, {useEffect, useState} from "react"
import Select, {CSSObjectWithLabel, MultiValue, StylesConfig} from "react-select"
import {ReactSVG} from "react-svg"
import {debounce} from "lodash"
import {HeaderGroup as TanHeaderGroup, Column, RowData} from "@tanstack/react-table"

interface DropdownOption {
  value: string
  label: string
  color?: string
}

interface HeaderGroup<TData> extends TanHeaderGroup<TData> {
  column?: Column<TData, unknown>
}

interface DropdownFilterProps<TData> {
  header: HeaderGroup<TData>
  options: DropdownOption[]
  isMultiSelect?: boolean
  isSearchable?: boolean
  filterResetKey: number
  showIcon?: boolean
}

const valueDisplayMapping: Record<string, string> = {
  true: "Ano",
  false: "Ne",
}

const mapValueToLabel = (value: string): string => {
  return value === "true" || value === "false" ? valueDisplayMapping[value] : value
}

const colourStyles: StylesConfig<DropdownOption, true> = {
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? "#51b687" : base.borderColor,
    boxShadow: "none",
    borderRadius: "6px",
    "&:hover": {
      borderColor: "#51b687",
    },
  }),
  option: (base, state) => ({
    ...base,
    color: "#51B687",
    backgroundColor: state.isSelected ? "#dcf0e7" : state.isFocused ? "#E7EAED" : base.backgroundColor,
  }),
  multiValue: (base: CSSObjectWithLabel) => ({
    ...base,
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingLeft: "6px",
    paddingRight: "6px",
    borderRadius: "20px",
    lineHeight: "normal",
  }),
  singleValue: (base: CSSObjectWithLabel) => ({
    ...base,
    textAlign: "left",
  }),
  multiValueRemove: (base: CSSObjectWithLabel) => ({
    ...base,
    borderRadius: "50%",
    verticalAlign: "middle",
  }),
  valueContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    zIndex: 0,
  }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    textAlign: "left",
  }),
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#51B687",
      borderRadius: "8px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#2d875d",
    },
  }),
}

export const DropdownFilter: React.FC<DropdownFilterProps<RowData>> = ({
  header,
  options,
  isMultiSelect = false,
  isSearchable = true,
  showIcon = false,
  filterResetKey,
}) => {
  const [inputValue, setInputValue] = useState("")
  const debouncedSetFilterValue = debounce(header.column?.setFilterValue ?? (() => {}), 300)
  const [selectedValues, setSelectedValues] = useState<string[]>([])

  useEffect(() => {
    setSelectedValues([])
    setInputValue("")
  }, [filterResetKey])

  const handleInputChange = (inputValue: string, {action}: {action: string}) => {
    if (action === "input-change") {
      setInputValue(inputValue)
      if (isMultiSelect) {
        debouncedSetFilterValue([...selectedValues, inputValue])
      } else {
        debouncedSetFilterValue(inputValue)
      }
    }
  }

  const handleOptionChange = (selectedOptions: MultiValue<DropdownOption>) => {
    if (isMultiSelect) {
      const newSelectedValues = Array.isArray(selectedOptions) ? selectedOptions.map((option: DropdownOption) => option.value) : []
      setSelectedValues(newSelectedValues)
      header.column?.setFilterValue(newSelectedValues.length > 0 ? newSelectedValues : undefined)
    } else {
      let selectedValue = ""
      if (selectedOptions) {
        selectedValue = (selectedOptions as unknown as DropdownOption).value
      }
      header.column?.setFilterValue(selectedValue)
    }
    setInputValue("")
  }

  const mappedOptions: DropdownOption[] = options.map((option) => ({
    ...option,
    label: mapValueToLabel(option.value),
  }))

  return (
    <Select
      className={isMultiSelect ? "basic-multi-select" : "basic-single"}
      inputValue={isSearchable ? inputValue : undefined}
      key={filterResetKey}
      onInputChange={isSearchable ? handleInputChange : undefined}
      menuPortalTarget={document.body}
      styles={{
        ...colourStyles,
        menuPortal: (base) => ({...base, zIndex: 9999}),
      }}
      {...(isMultiSelect && {isMulti: true})}
      classNamePrefix="select"
      components={{
        Placeholder: () => (
          <div className="dropdown-placeholder">
            <span className="dropdown-placeholder__text">Vše</span>
            {showIcon && (
              <span className="placeholder-icon__wrapper d-flex justify-content-center align-items-center">
                <ReactSVG src="/images/svg/chevron-down.svg" className="placeholder-icon" />
              </span>
            )}
          </div>
        ),
      }}
      classNames={{
        valueContainer: () => `value-container`,
      }}
      isDisabled={false}
      placeholder="Vše"
      noOptionsMessage={() => null}
      isLoading={false}
      isClearable={true}
      isRtl={false}
      isSearchable={isSearchable}
      name="select"
      options={mappedOptions}
      onChange={handleOptionChange}
    />
  )
}
