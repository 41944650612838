import {Option} from "slim-select/src/slim-select/store"
import {SlimSelectElement} from "js/slim-select/slim-select"

function init() {
  const dueDate = document.querySelector<SlimSelectElement>(".js-company-info-due-date select")
  const paymentType = document.querySelector<SlimSelectElement>(".js-company-info-payment-type select")

  if (!dueDate || !paymentType) return

  const dueDateSlim = dueDate.slim
  const paymentTypeSlim = paymentType.slim

  const checkPaymentTypeAndAdjustDueDate = () => {
    const options = paymentTypeSlim.getData()

    const isOptionWithClass = (option: Partial<Option>): option is Option & {class: string} =>
      typeof option.value === "string" && typeof option.class === "string"

    const isDueDateDependencyPresent = options.some(
      (option) => isOptionWithClass(option) && option.selected && option.class.includes("--dependency-due_date"),
    )

    if (isDueDateDependencyPresent) {
      dueDateSlim.enable()
      dueDateSlim.setSelected("14", true)
    } else {
      dueDateSlim.disable()
      dueDateSlim.setSelected("", true)
    }
  }

  paymentType.addEventListener("change", checkPaymentTypeAndAdjustDueDate)
}

init()
