const switchFields = document.querySelectorAll<HTMLElement>(".f-switch.--watch-password")

switchFields.forEach((switchField) => {
  const input = switchField.querySelector<HTMLInputElement>(".f-switch__input")
  const watchElement = document.querySelector<HTMLInputElement>(`#${switchField.dataset.watchTarget}`)

  watchElement.addEventListener("input", () => {
    handleActive()
  })

  const handleActive = () => {
    const isEmpty = !!watchElement.value.trim().length
    isEmpty ? enableSwitch() : disableSwitch()
  }

  watchElement.addEventListener("isDisabled", () => {
    disableSwitch()
  })

  watchElement.addEventListener("isActive", () => {
    handleActive()
  })

  function disableSwitch() {
    input.disabled = true
    input.checked = false
    switchField.classList.add("--disabled")
  }

  function enableSwitch() {
    input.disabled = false
    switchField.classList.remove("--disabled")
  }
})
