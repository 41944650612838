import {useState, useMemo} from "react"
import {SortingState, ColumnFiltersState, ExpandedState} from "@tanstack/react-table"

interface TableStateHook<TData> {
  sorting: SortingState
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>
  columnFilters: ColumnFiltersState
  setColumnFilters: React.Dispatch<React.SetStateAction<ColumnFiltersState>>
  rowSelection: Record<string, boolean>
  setRowSelection: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  selectedData: TData[]
  columnOrder: string[]
  setColumnOrder: React.Dispatch<React.SetStateAction<string[]>>
  columnVisibility: Record<string, boolean>
  setColumnVisibility: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  expanded: ExpandedState
  setExpanded: React.Dispatch<React.SetStateAction<ExpandedState>>
  filterResetKey: number
  setFilterResetKey: React.Dispatch<React.SetStateAction<number>>
}

export function useTableState<TData>(data: TData[]): TableStateHook<TData> {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const [columnOrder, setColumnOrder] = useState<string[]>([])
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({})
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [filterResetKey, setFilterResetKey] = useState<number>(0)

  const selectedData = useMemo(() => {
    return data.filter((_, index) => rowSelection[index])
  }, [data, rowSelection])

  return {
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters,
    rowSelection,
    setRowSelection,
    selectedData,
    columnOrder,
    setColumnOrder,
    columnVisibility,
    filterResetKey,
    setFilterResetKey,
    setColumnVisibility,
    expanded,
    setExpanded,
  }
}
