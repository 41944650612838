import {SlimSelectElement} from "js/slim-select/slim-select"

export {}

document.querySelectorAll(".f-countries_currencies").forEach((form) => {
  const country = form.querySelector<SlimSelectElement>(".js-country select")
  const abbreviation = form.querySelector<SlimSelectElement>(".js-abbreviation select")

  console.log(country, abbreviation)

  if (!country || !abbreviation) return

  country.slim.render.callbacks.afterChange = () => {
    const selected = country.slim.getSelected().find(Boolean)

    console.log(selected)

    abbreviation.slim.setSelected(selected, false)
  }

  country.slim.render.enable() // I guess update slim in order to enable added callback
})
