import React from "react"

import {Table} from "@tanstack/react-table"
import {Button} from "@components/button/button"

interface PaginationProps<TData> {
  readonly table: Table<TData>
}

export function DataGridPagination<TData>({table}: PaginationProps<TData>) {
  return (
    <div className="d-flex gap-3 ms-auto align-items-center flex-wrap">
      <div className="">
        Stránka {table.getState().pagination.pageIndex + 1} z {table.getPageCount()}
      </div>

      <div className="d-flex gap-1 align-items-center">
        <Button
          size="xs"
          tag="button"
          variant="outline"
          type="button"
          iconBefore={true}
          iconOnly={true}
          disabled={!table.getCanPreviousPage()}
          title="Předchozí"
          onClick={() => table.previousPage()}
          icon={{
            src: "/images/svg/chevron-left.svg",
          }}
          animation="left"
        />
        <Button
          size="xs"
          tag="button"
          variant="outline"
          type="button"
          disabled={!table.getCanNextPage()}
          title="Další"
          onClick={() => table.nextPage()}
          icon={{
            src: "/images/svg/chevron-right.svg",
          }}
          iconOnly={true}
          animation="right"
        />
      </div>
    </div>
  )
}
