import React from "react"
import ReactDOM from "react-dom/client"
import {DataTable} from "./rtp-large-datatable"
import {TDocuments, THeaders, getColumns} from "./rtp-large-columns"

type Table = {
  data: TDocuments[]
  headers: THeaders
}

const TableComponent: React.FC<{tableElement: HTMLElement}> = ({tableElement}) => {
  const table = JSON.parse(tableElement.dataset.table ?? "null") as Table | null

  if (!table) return null

  return (
    <React.StrictMode>
      <DataTable data={table.data} columns={getColumns(table.headers)} />
    </React.StrictMode>
  )
}

const tables = document.querySelectorAll<HTMLElement>(".rtp-large-table")

tables.forEach((tableElement) => {
  ReactDOM.createRoot(tableElement).render(<TableComponent tableElement={tableElement} />)
})
