import React from "react"
import ReactDOM from "react-dom/client"
import {DataTable} from "./documents-datatable"
import {TDocument, THeaders, getColumns} from "./documents-columns"
import {ButtonsActions} from "@components/tables/components/table-layout"

export {}

const tables = document.querySelectorAll<HTMLElement>(".shopper-documents-table")

type Table = {
  data: TDocument[]
  headers: THeaders
  buttons: ButtonsActions
}

tables.forEach((tableElement) => {
  const table = JSON.parse(tableElement.dataset.table ?? "null") as Table | null
  if (!table) return

  ReactDOM.createRoot(tableElement).render(
    <React.StrictMode>
      <DataTable data={table.data} columns={getColumns(table.headers)} buttons={table.buttons} />
    </React.StrictMode>,
  )
})
