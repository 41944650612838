import {FilterFn, RowData} from "@tanstack/react-table"

interface Row {
  getValue: (columnId: string) => string
}

type FilterValueIncludes = string | string[]
type FilterValueRange = [string | null, string | null]

type FilterFnIncludes = (row: Row, columnId: string, filterValue: FilterValueIncludes) => boolean
type FilterFnRange = (row: Row, columnId: string, filterValue: FilterValueRange) => boolean
type FilterFnBoolean = (row: Row, columnId: string, filterValue: string) => boolean

export type CustomFilterFn<TData extends RowData> = FilterFn<TData> | FilterFnIncludes | FilterFnRange

export const customFilterFns: Record<string, FilterFnIncludes | FilterFnRange | FilterFnBoolean> = {
  multiSelect: (row: Row, columnId: string, filterValue: FilterValueIncludes) => {
    const rowValue = row.getValue(columnId).toLowerCase()
    if (Array.isArray(filterValue)) {
      return filterValue.some((value) => rowValue.includes(value.toLowerCase()))
    } else {
      return rowValue.includes(filterValue.toLowerCase())
    }
  },
  inDateRange: (row: Row, columnId: string, filterValue: FilterValueRange) => {
    const [startDate, endDate] = filterValue
    const date = new Date(row.getValue(columnId))
    return (startDate === null || new Date(startDate) <= date) && (endDate === null || date <= new Date(endDate))
  },
  matchesBoolean: (row: Row, columnId: string, filterValue: string) => {
    const rowValue = row.getValue(columnId).toLowerCase() === "true"
    const booleanFilterValue = filterValue.toLowerCase() === "true"
    return rowValue === booleanFilterValue
  },
  inNumberRange: (row: Row, columnId: string, filterValue: FilterValueRange) => {
    const [min, max] = filterValue
    const value = Number(row.getValue(columnId))

    if (min !== null && max !== null) {
      return value >= Number(min) && value <= Number(max)
    } else if (min !== null) {
      return value >= Number(min)
    } else if (max !== null) {
      return value <= Number(max)
    } else {
      return true
    }
  },
}
