import React, {useEffect, useRef, useState} from "react"
import {flexRender} from "@tanstack/react-table"
import {NumberRangeFilter} from "./table-filters/number-range-filter"
import {DropdownFilter} from "./table-filters/dropdown-filter"
import {DateFilter} from "./table-filters/date-filter"
import {customFilterFns} from "./table-filters/custom-filter-fns"

import {ReactSVG} from "react-svg"
import {TableProps, HeaderGroup, TableBodyProps, TableHeaderProps} from "./table.types"

const TableHeader = <TData,>({headerGroups, selectedData, tableWrapperRef, columnConfig, filterResetKey}: TableHeaderProps<TData>) => {
  const renderFilter = <TData,>(
    header: HeaderGroup<TData>,
    options: {value: string; label: string}[],
    isMultiSelect: boolean,
    isSearchable: boolean,
    showIcon: boolean,
    filterResetKey: number,
  ) => {
    const columnDef = header.column?.columnDef
    if (!columnDef?.enableColumnFilter) return null

    switch (columnDef.filterFn) {
      case customFilterFns.inDateRange:
        return <DateFilter header={header} filterResetKey={filterResetKey} />
      case customFilterFns.inNumberRange:
        return <NumberRangeFilter header={header} filterResetKey={filterResetKey} />
      default:
        return (
          <DropdownFilter
            header={header as HeaderGroup<unknown>}
            options={options}
            isMultiSelect={isMultiSelect}
            isSearchable={isSearchable}
            showIcon={showIcon}
            filterResetKey={filterResetKey}
          />
        )
    }
  }

  const [tableWidth, setTableWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      if (tableWrapperRef.current) {
        const style = window.getComputedStyle(tableWrapperRef.current)
        const marginRight = parseFloat(style.marginRight)
        const width = tableWrapperRef.current.getBoundingClientRect().width + marginRight
        setTableWidth(width)
      }
    }

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <thead>
      {headerGroups.map((group) => (
        <tr key={group.id}>
          {group.headers.map((header) => {
            const uniqueValues = [...new Set(Array.from(header.column.getFacetedUniqueValues().keys()).flat(1))]
            const options = uniqueValues.map((value) => ({value, label: value}))
            const columnConfigItem = columnConfig.find((config) => config.name === header.id)
            const isMultiSelect = columnConfigItem?.multiSelect ?? false
            const isSearchable = columnConfigItem?.searchable ?? false
            const showIcon = columnConfigItem?.showIcon ?? false
            return (
              <th
                key={header.id}
                style={{
                  width: header.getSize(),
                  position: header.id === "selection" ? "static" : undefined,
                  opacity: (selectedData?.length ?? 0) > 0 && header.id !== "selection" ? 0 : 1,
                }}
              >
                <div
                  className={`th-content ${header.id === "selection" && (selectedData?.length ?? 0) > 0 ? "--th-content__selected" : ""}`}
                  style={{maxWidth: header.id === "selection" && (selectedData?.length ?? 0) > 0 ? tableWidth : "auto"}}
                >
                  <div className="w-100">
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {renderFilter(
                      header as unknown as HeaderGroup<TData>,
                      options,
                      isMultiSelect,
                      isSearchable,
                      showIcon,
                      filterResetKey,
                    )}
                  </div>
                  {header.column.getCanResize() && (
                    <ReactSVG
                      src="/images/svg/resizer.svg"
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      className="resizer"
                    />
                  )}
                </div>
              </th>
            )
          })}
        </tr>
      ))}
    </thead>
  )
}

const TableBody = <TData,>({rows}: TableBodyProps<TData>) => (
  <tbody>
    {rows.map((row) => (
      <tr key={row.id}>
        {row.getVisibleCells().map((cell, index) => (
          <td
            key={cell.id}
            style={{
              width: cell.column.getSize(),
              ...(index !== 0 ? {maxWidth: "150px"} : {}),
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
)

export function Table<TData>({table, multiSelectColumns, selectedData, columnConfig, filterResetKey, extra_class}: TableProps<TData>) {
  const tableWrapperRef = useRef<HTMLDivElement>(null)

  return (
    <div
      className={`table_wrapper u-scrollbar-native ${extra_class} ${(selectedData?.length ?? 0) > 0 ? "--table-selected" : ""}`}
      ref={tableWrapperRef}
    >
      <table style={{width: table.getCenterTotalSize(), minHeight: "159px"}}>
        <TableHeader
          headerGroups={table.getHeaderGroups()}
          multiSelectColumns={multiSelectColumns}
          selectedData={selectedData}
          columnConfig={columnConfig}
          tableWrapperRef={tableWrapperRef}
          filterResetKey={filterResetKey}
        />
        <TableBody rows={table.getRowModel().rows} />
      </table>
    </div>
  )
}
