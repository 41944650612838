import React, {useState, useEffect} from "react"
import {Column} from "@tanstack/react-table"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

interface Header<TData> {
  column?: Column<TData>
}

interface DateFilterProps<TData> {
  header: Header<TData>
  filterResetKey: number
}

export const DateFilter = <TData,>({header, filterResetKey}: DateFilterProps<TData>) => {
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  useEffect(() => {
    if (startDate === null && endDate === null) {
      header.column?.setFilterValue([null, null])
    } else {
      header.column?.setFilterValue([startDate || null, endDate || null])
    }
  }, [startDate, endDate, header])

  useEffect(() => {
    setStartDate(null)
    setEndDate(null)
  }, [filterResetKey])

  return (
    <div className="date-filter-container">
      <DatePicker
        className="date-filter-input start-date-input"
        selected={startDate}
        onChange={(date: Date) => setStartDate(date)}
        placeholderText="Od"
      />
      <DatePicker
        className="date-filter-input end-date-input"
        selected={endDate}
        onChange={(date: Date) => setEndDate(date)}
        placeholderText="Do"
      />
    </div>
  )
}
