export {}

const navItems = document.querySelectorAll(".i-main-nav.--has-submenu")

navItems.forEach((navItem) => {
  const link = navItem.querySelector(".i-main-nav__link")

  link?.addEventListener("click", (event) => {
    event.preventDefault()
    navItem.classList.toggle("--open")
  })
})
