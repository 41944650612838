import React, {useState, useRef, useCallback} from "react"
import {useDrag, useDrop, DndProvider} from "react-dnd"
import update from "immutability-helper"
import {HTML5Backend} from "react-dnd-html5-backend"
import {ReactSVG} from "react-svg"
import {RowData, Table} from "@tanstack/react-table"
import { DndManager } from "@components/tables/utils/dndManager"
import CheckboxField from "@components/fields/checkbox-field/checkbox-field"

interface DraggableColumnListProps<TData extends RowData> {
  setColumnOrder?: React.Dispatch<React.SetStateAction<string[]>>
  columnNames: Record<string, string>
  table: Table<TData>
}

interface Item {
  id: string
  text: string
  index: number
}

interface DragItem {
  index: number
  id: number
  type: string
}

const ItemTypes = {
  CARD: "card",
}

interface CardProps<TData extends RowData> {
  id: string
  text: string
  index: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
  table: Table<TData>
}

const Card = <TData extends RowData>({id, text, index, moveCard, table}: CardProps<TData>) => {
  const column = table.getAllColumns().find((col) => col.id === id)

  const handleToggleVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckedState = event.target.checked;
    if (column) {
      column.getToggleVisibilityHandler()({target: {checked: newCheckedState}} as React.ChangeEvent<HTMLInputElement>)
    }
  }

  const ref = useRef<HTMLDivElement>(null)
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset ? clientOffset.y - hoverBoundingRect.top : 0
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{isDragging}, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return {id, index}
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))
  return (
    <div ref={ref} className={`collapsible__card d-flex gap-1 align-items-center justify-items-center ${isDragging ? "dragging" : ""}`}>
      <ReactSVG src="/images/svg/sort.svg" />
      <CheckboxField
      name='column-visibility'
      checked={column ? column.getIsVisible() : false}
      onChange={handleToggleVisibility}
      />
      {text}
    </div>
  )
}

const DraggableColumnList = <TData extends RowData>({setColumnOrder, columnNames, table}: DraggableColumnListProps<TData>) => {
  const [cards, setCards] = useState<Item[]>(
    Object.entries(columnNames)
      .filter(([id]) => id !== "selection")
      .map(([id, text], index) => ({
        id,
        text,
        index,
      })),
  )

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setCards((prevCards: Item[]) => {
        const newCards = update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        })
        const orderedCards = ["selection", ...newCards.filter((card) => card.id !== "selection").map((card) => card.id)]
        if (setColumnOrder) {
          setColumnOrder(orderedCards)
        }
        return newCards
      })
    },
    [setColumnOrder],
  )

  return (
    <DndProvider backend={HTML5Backend}
      manager={DndManager}
    >
      <div className="collapsible__card-list">
        {cards.map((card, i) => (
          <Card key={card.id} index={i} id={card.id} text={card.text} moveCard={moveCard} table={table} />
        ))}
      </div>
    </DndProvider>
  )
}

export default DraggableColumnList
