import React from "react"
import {ColumnDef} from "@tanstack/react-table"
import {useDataTable} from "@components/data-grid/hooks/useDataTable"
import TableLayout from "@components/tables/components/table-layout"
import {getColNames} from "@components/tables/utils/getColNames"

interface DataTableProps<TData, TValue> {
  readonly columns: ColumnDef<TData, TValue>[]
  readonly data: TData[]
}

export function DataTable<TData, TValue>({columns, data}: DataTableProps<TData, TValue>) {
  const {table, selectedData, setColumnOrder} = useDataTable({columns, data})

  const columnNames = getColNames(columns)

  const columnConfig = []

  return (
    <TableLayout
      table={table}
      hasCollapsible={true}
      setColumnOrder={setColumnOrder}
      columnNames={columnNames}
      columnConfig={columnConfig}
      hideColumnReordering={true}
      hideFooterActions={true}
      extra_class="rtb-small-table"
    />
  )
}
