import SlimSelect, {Config} from "slim-select"

export const applySlimSelectStylesMulti = (slimSelectInstance: SlimSelect) => {
  slimSelectInstance.render.main.arrow.main.setAttribute("width", "24")
  slimSelectInstance.render.main.arrow.main.setAttribute("height", "24")
  slimSelectInstance.render.main.arrow.main.setAttribute("viewBox", "0 0 24 24")
  slimSelectInstance.render.main.arrow.main.setAttribute("fill", "none")

  slimSelectInstance.render.main.arrow.path.setAttribute("d", "M4.5 7.5L12 15L19.5 7.5")
  slimSelectInstance.render.main.arrow.path.setAttribute("stroke", "#15324F")
  slimSelectInstance.render.main.arrow.path.setAttribute("stroke-width", "2")

  slimSelectInstance.render.classes.arrowClose = "M4.5 7.5L12 15L19.5 7.5"
  slimSelectInstance.render.classes.arrowOpen = "M4.5 15L12 7.5L19.5 15"
}

const selects = document.querySelectorAll(".f-multi-select")

selects.forEach((select) => {
  const selectInput = select.querySelector(".f-multi-select__select.slim-select")

  if (!selectInput) return

  const hasSearch = selectInput.hasAttribute("data-has-search")

  const settings: Config = {
    select: selectInput,
    settings: {
      placeholderText: "",
      allowDeselect: true,
      showSearch: hasSearch,
      closeOnSelect: false,
      searchPlaceholder: selectInput.getAttribute("data-search-placeholder") ?? "",
      searchText: selectInput.getAttribute("data-search-no-results") ?? "",
      minSelected: Number(selectInput.getAttribute("data-min")) ?? undefined,
      maxSelected: Number(selectInput.getAttribute("data-max")) ?? undefined,
    },
  }

  const multi = new SlimSelect(settings)
  applySlimSelectStylesMulti(multi)
  select.classList.remove("--native")
})
