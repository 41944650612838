import React, {useState} from "react"
import {Table, RowData} from "@tanstack/react-table"
import CheckboxField from "@components/fields/checkbox-field/checkbox-field"
import ActionButton from "@components/tables/components/action-button"
import {DeleteModal} from "@components/modal/delete-modal"

interface RowSelectorProps<TData extends RowData> {
  table: Table<TData>
  modalTitle?: string
  deleteUrl?: string
  hideDeleteModal?: boolean
  active_permissions?: string[]
  fetch_data_url?: string
  showTitle: boolean
  hideSelectAll?: boolean
}

const RowSelector = <TData extends RowData>({table, modalTitle, deleteUrl, hideDeleteModal, showTitle, hideSelectAll}: RowSelectorProps<TData>) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const selectedRows = table.getRowModel().rows.filter((row) => row.getIsSelected())

  const isAnySelected = selectedRows.length > 0

  const getRowSelectionText = (count: number) => {
    if (count === 1) return "položka"
    if (count > 1 && count < 5) return "položky"
    return "položek"
  }

  return (
    <div className={`selection-column__header ${isAnySelected ? "--selected" : ""}`}>
      {showTitle && <span className="selection-column__allowed">Povolené</span>}
      {!hideSelectAll && (<CheckboxField
        name="select-checkbox"
        useMinusIcon={true}
        checked={isAnySelected}
        onChange={() => {
          const newValue = !isAnySelected
          table.getRowModel().rows.forEach((row) => row.toggleSelected(newValue))
        }}
      />
      )}
      {isAnySelected && (
        <>
          <span>
            {selectedRows.length} {getRowSelectionText(selectedRows.length)}
          </span>

          {!hideDeleteModal && <ActionButton iconSrc="/images/svg/delete.svg" onClick={() => setIsDeleteModalOpen(true)} />}
        </>
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          id="deleteModal"
          open={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          title={modalTitle || "Opravdu si přejete pokračovat v této akci?"}
          selectedRowIds={selectedRows.map((row) => (row.original as TData & {id: string}).id)}
          deleteUrl={deleteUrl}
        />
      )}
    </div>
  )
}

export default RowSelector
