import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"
import SwitchVisual from "@components/tables/components/switch-visual"
import {selectionColumn} from "@components/data-grid/selection-column"
import TooltipTruncated from "@components/tables/components/tooltip-truncated"
import {TableActions} from "@components/tables/components/table-layout"

export type TListings = {
  selection: string
  company_name: string
  slogan: string
  country: string
  street: string
  city: string
  postal_code: string
  ic_number: string
  dic_number: string
  email: string
  phone_number: string
  company_type: string
  pricing: string
  parent_company: string
  currency: string
  payment_type: string | string[]
  marketing_field: string
  notifications: string
  id: string
  url: string
}

export type THeaders = {
  selection: string
  company_name: string
  slogan: string
  country: string
  street: string
  city: string
  postal_code: string
  ic_number: string
  dic_number: string
  email: string
  phone_number: string
  company_type: string
  pricing: string
  parent_company: string
  currency: string
  payment_type: string
  marketing_field: string
  notifications: string
  id: string
  url: string
}

const columnHelper = createColumnHelper<TListings>()

export const getColumns = (headers: THeaders, actions: TableActions) => {
  return [
    selectionColumn<TListings>((row) => row.id, {
      checkboxFieldName: "select-checkbox",
      editButton: true,
      duplicateButton: true,
      delete_button: true,
      actions,
    }),
    columnHelper.accessor((row) => row.company_name, {
      id: "company_name",
      footer: headers.company_name,
      header: ({column}) => <ColumnHeader title={headers.company_name} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.slogan, {
      id: "slogan",
      footer: headers.slogan,
      header: ({column}) => <ColumnHeader title={headers.slogan} column={column} />,
      cell: (props) => (
        <>
          <span className="slogan-tooltip" data-tooltip-id="slogan-tooltip">
            {props.getValue()}
          </span>
          <TooltipTruncated id="slogan-tooltip" text={props.getValue()} />
        </>
      ),
      size: 300,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.country, {
      id: "country",
      footer: headers.country,
      header: ({column}) => <ColumnHeader title={headers.country} column={column} />,
      filterFn: customFilterFns.multiSelect,
      enableColumnFilter: true,
      size: 300,
    }),
    columnHelper.accessor((row) => row.street, {
      id: "street",
      footer: headers.street,
      header: ({column}) => <ColumnHeader title={headers.street} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.city, {
      id: "city",
      footer: headers.city,
      header: ({column}) => <ColumnHeader title={headers.city} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.postal_code, {
      id: "postal_code",
      footer: headers.postal_code,
      header: ({column}) => <ColumnHeader title={headers.postal_code} column={column} />,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.ic_number, {
      id: "ic_number",
      footer: headers.ic_number,
      header: ({column}) => <ColumnHeader title={headers.ic_number} column={column} />,
      filterFn: customFilterFns.multiSelect,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.dic_number, {
      id: "dic_number",
      footer: headers.dic_number,
      header: ({column}) => <ColumnHeader title={headers.dic_number} column={column} />,
      filterFn: customFilterFns.multiSelect,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.email, {
      id: "email",
      footer: headers.email,
      header: ({column}) => <ColumnHeader title={headers.email} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.phone_number, {
      id: "phone_number",
      footer: headers.phone_number,
      header: ({column}) => <ColumnHeader title={headers.phone_number} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.company_type, {
      id: "company_type",
      footer: headers.company_type,
      header: ({column}) => <ColumnHeader title={headers.company_type} column={column} />,
      filterFn: customFilterFns.multiSelect,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.pricing, {
      id: "pricing",
      footer: headers.pricing,
      header: ({column}) => <ColumnHeader title={headers.pricing} column={column} />,
      filterFn: customFilterFns.multiSelect,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.parent_company, {
      id: "parent_company",
      footer: headers.parent_company,
      header: ({column}) => <ColumnHeader title={headers.parent_company} column={column} />,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.currency, {
      id: "currency",
      footer: headers.currency,
      header: ({column}) => <ColumnHeader title={headers.currency} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.multiSelect,
    }),
    columnHelper.accessor((row) => row.payment_type, {
      id: "payment_type",
      footer: headers.payment_type,
      header: ({column}) => <ColumnHeader title={headers.payment_type} column={column} />,
      enableColumnFilter: true,
      filterFn: "arrIncludesSome",
      size: 250,
      cell: (props) => {
        const value = props.getValue()
        if (Array.isArray(value)) {
          return (
            <div className="d-flex flex-wrap gap-1">
              {value.map((item) => (
                <div className="i-tag u-fw-600 d-inline-flex --sm --secondary" key={item}>
                  {item}
                </div>
              ))}
            </div>
          )
        } else {
          return <div className="i-tag u-fw-600 d-inline-flex --sm --secondary">{value}</div>
        }
      },
    }),
    columnHelper.accessor((row) => row.marketing_field, {
      id: "marketing_field",
      footer: headers.marketing_field,
      header: ({column}) => <ColumnHeader title={headers.marketing_field} column={column} />,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      enableColumnFilter: true,
      size: 180,
    }),
    columnHelper.accessor((row) => row.notifications, {
      id: "notifications",
      footer: headers.notifications,
      header: ({column}) => <ColumnHeader title={headers.notifications} column={column} />,
      enableColumnFilter: true,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      size: 180,
    }),
    columnHelper.accessor((row) => row.id, {
      id: "id",
      footer: headers.id,
      header: ({column}) => <ColumnHeader title={headers.id} column={column} />,
      enableColumnFilter: true,
    }),
  ]
}
