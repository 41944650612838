import {Theme, defaultTheme} from "react-select"

export const customTheme: Theme = {
  ...defaultTheme,
  borderRadius: 6,
  colors: {
    ...defaultTheme.colors,
    primary: "var(--c-primary)",
    neutral20: "var(--c-secondary-400)",
    primary25: "var(--c-secondary-200)",
  },
  spacing: {
    ...defaultTheme.spacing,
    controlHeight: 48,
  },
}
