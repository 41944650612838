import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"
import SwitchVisual from "@components/tables/components/switch-visual"
import {selectionColumn} from "@components/data-grid/selection-column"
import {TableActions} from "@components/tables/components/table-layout"

export type TListings = {
  selection: string
  id: string
  demand_number: string
  demand_type: string
  date: string
  demand_name: string
  currency: string
  connected_demand: string
  registered: string
  company_name: string
  contact_person: string
  email: string
  country_of_delivery: string
  phone_number: string
  demand_note: string
  demand_source: string
  demand_status: string
  documents: string
  last_activity_date: string
  contact_options: string
  recontact_date: string
  demand_user: string
  demanded_date: string
}

export type THeaders = {
  selection: string
  id: string
  demand_number: string
  demand_type: string
  date: string
  demand_name: string
  currency: string
  connected_demand: string
  registered: string
  company_name: string
  contact_person: string
  email: string
  country_of_delivery: string
  phone_number: string
  demand_note: string
  demand_source: string
  demand_status: string
  documents: string
  last_activity_date: string
  contact_options: string
  recontact_date: string
  demand_user: string
  demanded_date: string
}

const columnHelper = createColumnHelper<TListings>()

export const getColumns = (headers: THeaders, actions: TableActions) => {
  return [
    selectionColumn<TListings>((row) => row.id, {
      checkboxFieldName: "select-checkbox",
      editButton: true,
      modalTitle: "Opravdu si přejete odstranit tohoto administrátora?",
      duplicateButton: true,
      delete_button: true,
      actions,
    }),
    columnHelper.accessor((row) => row.demand_number, {
      id: "demand_number",
      footer: headers.demand_number,
      header: ({column}) => <ColumnHeader title={headers.demand_number} column={column} />,
      enableColumnFilter: true,
      size: 250,
      filterFn: customFilterFns.multiSelect,
    }),
    columnHelper.accessor((row) => row.demand_type, {
      id: "demand_type",
      footer: headers.demand_type,
      header: ({column}) => <ColumnHeader title={headers.demand_type} column={column} />,
      enableColumnFilter: true,
      size: 250,
      filterFn: customFilterFns.multiSelect,
    }),
    columnHelper.accessor((row) => row.date, {
      id: "date",
      footer: headers.date,
      filterFn: customFilterFns.inDateRange,
      enableColumnFilter: true,
      header: ({column}) => <ColumnHeader title={headers.date} column={column} />,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
    }),
    columnHelper.accessor((row) => row.demand_name, {
      id: "demand_name",
      footer: headers.demand_name,
      header: ({column}) => <ColumnHeader title={headers.demand_name} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.currency, {
      id: "currency",
      footer: headers.currency,
      header: ({column}) => <ColumnHeader title={headers.currency} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.connected_demand, {
      id: "connected_demand",
      footer: headers.connected_demand,
      header: ({column}) => <ColumnHeader title={headers.connected_demand} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.registered, {
      id: "registered",
      footer: headers.registered,
      header: ({column}) => <ColumnHeader title={headers.registered} column={column} />,
      enableColumnFilter: true,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      size: 250,
    }),
    columnHelper.accessor((row) => row.company_name, {
      id: "company_name",
      footer: headers.company_name,
      header: ({column}) => <ColumnHeader title={headers.company_name} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.contact_person, {
      id: "contact_person",
      footer: headers.contact_person,
      header: ({column}) => <ColumnHeader title={headers.contact_person} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.email, {
      id: "email",
      footer: headers.email,
      header: ({column}) => <ColumnHeader title={headers.email} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.country_of_delivery, {
      id: "country_of_delivery",
      footer: headers.country_of_delivery,
      header: ({column}) => <ColumnHeader title={headers.country_of_delivery} column={column} />,
      enableColumnFilter: true,
      size: 250,
      filterFn: customFilterFns.multiSelect,
    }),
    columnHelper.accessor((row) => row.phone_number, {
      id: "phone_number",
      footer: headers.phone_number,
      header: ({column}) => <ColumnHeader title={headers.phone_number} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.demand_note, {
      id: "demand_note",
      footer: headers.demand_note,
      header: ({column}) => <ColumnHeader title={headers.demand_note} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.demand_source, {
      id: "demand_source",
      footer: headers.demand_source,
      header: ({column}) => <ColumnHeader title={headers.demand_source} column={column} />,
      enableColumnFilter: true,
      size: 250,
      filterFn: customFilterFns.multiSelect,
    }),
    columnHelper.accessor((row) => row.demand_status, {
      id: "demand_status",
      footer: headers.demand_status,
      header: ({column}) => <ColumnHeader title={headers.demand_status} column={column} />,
      enableColumnFilter: true,
      size: 250,
      filterFn: customFilterFns.multiSelect,
    }),
    columnHelper.accessor((row) => row.documents, {
      id: "documents",
      footer: headers.documents,
      header: ({column}) => <ColumnHeader title={headers.documents} column={column} />,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      filterFn: customFilterFns.matchesBoolean,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.last_activity_date, {
      id: "last_activity_date",
      filterFn: customFilterFns.inDateRange,
      enableColumnFilter: true,
      footer: headers.last_activity_date,
      header: ({column}) => <ColumnHeader title={headers.last_activity_date} column={column} />,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
    }),
    columnHelper.accessor((row) => row.contact_options, {
      id: "contact_options",
      footer: headers.contact_options,
      header: ({column}) => <ColumnHeader title={headers.contact_options} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.multiSelect,
      size: 250,
    }),
    columnHelper.accessor((row) => row.recontact_date, {
      id: "recontact_date",
      enableColumnFilter: true,
      filterFn: customFilterFns.inDateRange,
      footer: headers.recontact_date,
      header: ({column}) => <ColumnHeader title={headers.recontact_date} column={column} />,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
    }),
    columnHelper.accessor((row) => row.demand_user, {
      id: "demand_user",
      footer: headers.demand_user,
      header: ({column}) => <ColumnHeader title={headers.demand_user} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.demanded_date, {
      id: "demanded_date",
      filterFn: customFilterFns.inDateRange,
      enableColumnFilter: true,
      footer: headers.demanded_date,
      header: ({column}) => <ColumnHeader title={headers.demanded_date} column={column} />,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
    }),
  ]
}
