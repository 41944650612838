import clsx from "clsx"
import React, {ElementType} from "react"
import {ReactSVG} from "react-svg"

export type ButtonProps = {
  title?: string
  tag: "button" | "a" | "span"
  url?: string
  value?: string
  variant: "primary" | "outline" | "blank-white" | "blank"
  size?: "xs" | "sm" | "default" | "xxs"
  animation?: "right" | "zoom" | "left" | "down"
  className?: string
  iconOnly: boolean
  iconBefore: boolean
  fullWidth: boolean
  fullWidthMobile: boolean
  id?: string
  name?: string
  type?: "button" | "submit" | "reset"
  blank?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  icon?: {
    src: string
  }
}

const defaultProps: ButtonProps = {
  tag: "a",
  variant: "primary",
  iconOnly: false,
  iconBefore: false,
  fullWidth: false,
  fullWidthMobile: false,
}

export const Button = (props: ButtonProps) => {
  const Tag = props.tag as ElementType

  const classNames = clsx(
    `btn`,
    `u-posr u-cursor-pointer d-inline-flex gap-1 align-items-center justify-content-center flex-nowrap`,
    `--${props.variant}`,
    props.size && `--${props.size}`,
    props.animation && `--animation-${props.animation}`,
    props.iconOnly && "--icon-only",
    props.disabled && "--disabled",
    props.iconBefore && "--icon-before",
    props.fullWidth && "--full-width w-100",
    props.fullWidthMobile && "--full-width-mobile",
    props.className,
  )

  const attributes = {
    className: classNames,
    ...(props.id && {id: props.id}),
    ...(Tag === "a" && {target: `_blank`}),
    disabled: !!props.disabled,
    ...(Tag == "button" && {value: props.value}),
    ...(Tag == "button" && {type: props.type}),
    ...(Tag == "button" && {name: props.name}),
    ...(Tag == "button" && {"aria-label": props.title}),
    ...(Tag == "a" && {href: props.url}),
    ...(Tag == "a" && {title: props.title}),
    onClick: props.onClick,
  }

  return (
    <>
      <Tag {...attributes}>
        {props.title && !props.iconOnly && <span className="btn__title">{props.title}</span>}
        {props.icon && (
          <span className="btn__icon d-flex align-items-center justify-content-center">
            <ReactSVG {...props.icon} />
          </span>
        )}
      </Tag>
    </>
  )
}

Button.defaultProps = defaultProps
