import React from "react"
import Select from "react-select"
import {Table} from "@tanstack/react-table"
import {customTheme} from "@components/fields/react-select-field/react-select-field--styles"

interface PageSizeProps<TData> {
  readonly table: Table<TData>
}

export function PageSize<TData>({table}: PageSizeProps<TData>) {
  const pageSizes = [15, 30, 100, 500].map((pageSize) => {
    return {
      value: pageSize.toString(),
      label: pageSize.toString(),
    }
  })

  return (
    <div className="d-flex gap-1 align-items-center flex-wrap">
      <span>Položek na stránce</span>
      <Select
        options={pageSizes}
        onChange={(newValue, {action}) => {
          if (action === "select-option") {
            table.setPageSize(Number(newValue?.value))
          }
        }}
        defaultValue={pageSizes[0]}
        theme={customTheme}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={{
          dropdownIndicator: (base) => ({
            ...base,
            color: "var(--c-secondary)",
          }),
        }}
      />
    </div>
  )
}
