export {}

const elementsExist = (): boolean => {
  return !!document.querySelector(".js-company-info-subject-type select") && !!document.querySelector(".js-ares-ico input")
}

if (elementsExist()) {
  const subjectTypeField = document.querySelector(".js-company-info-subject-type select") as HTMLSelectElement
  const icoInputElement = document.querySelector(".js-ares-ico input") as HTMLInputElement
  const icoLabelElement = document.querySelector(".js-ares-ico label") as HTMLLabelElement

  const updateIcoFieldRequirement = () => {
    if (!subjectTypeField || !icoInputElement) {
      return
    }
    const selectedOption = subjectTypeField.options[subjectTypeField.selectedIndex]
    const isDependentOnIco = selectedOption.classList.contains("--dependency-ico")

    if (isDependentOnIco) {
      icoLabelElement.classList.add("--required")
      icoInputElement.required = true
    } else {
      icoLabelElement.classList.remove("--required")
      icoInputElement.required = false
    }
  }

  updateIcoFieldRequirement()

  if (subjectTypeField) {
    subjectTypeField.addEventListener("change", updateIcoFieldRequirement)
  }
}
