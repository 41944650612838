export type FieldTypes = "company" | "city" | "zip" | "vat" | "ico" | "street"

export class Field {
  type: FieldTypes
  input?: HTMLInputElement
  before?: HTMLElement
  after?: HTMLElement

  constructor(type: FieldTypes, input?: HTMLInputElement, before?: HTMLElement, after?: HTMLElement) {
    this.type = type
    this.input = input
    this.before = before
    this.after = after
  }

  tryGetInputValue() {
    if (!this.input) return
    return this.input.value
  }

  setBeforeByInput() {
    if (!this.before || !this.input) return
    this.before.innerHTML = this.input.value
  }

  replaceInputByAfter() {
    if (!this.after || !this.input) return
    this.input.value = this.after.innerText
  }

  setAfter(after: string) {
    if (!this.after) return
    this.after.innerText = after
  }

  isBeforeAfterSame() {
    if (!this.after || !this.before) return false
    return this.after.innerText === this.before.innerText
  }

  handleSame() {
    this.after?.classList.toggle("--focus", !this.isBeforeAfterSame())
  }
}
