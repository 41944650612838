import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"
import SwitchVisual from "@components/tables/components/switch-visual"
import {selectionColumn} from "@components/data-grid/selection-column"
import {TableActions} from "@components/tables/components/table-layout"

export type TListings = {
  selection: string
  pricelist_name: string,
  author: string,
  company_count: string,
  product_count: string,
  id: string
}

export type THeaders = {
  selection: string
  pricelist_name: string,
  author: string,
  company_count: string,
  product_count: string,
}

const columnHelper = createColumnHelper<TListings>()

export const getColumns = (headers: THeaders, actions: TableActions) => {
  return [
    selectionColumn<TListings>((row) => row.id, {
      checkboxFieldName: "select-checkbox",
      editButton: true,
      delete_button: false,
      duplicateButton: false,
      actions,
    }),
    columnHelper.accessor((row) => row.pricelist_name, {
      id: "pricelist_name",
      footer: headers.pricelist_name,
      header: ({column}) => <ColumnHeader title={headers.pricelist_name} column={column} />,
      enableColumnFilter: true,
      size: 300,
    }),
    columnHelper.accessor((row) => row.author, {
      id: "author",
      footer: headers.author,
      header: ({column}) => <ColumnHeader title={headers.author} column={column} />,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.company_count, {
      id: "company_count",
      footer: headers.company_count,
      header: ({column}) => <ColumnHeader title={headers.company_count} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.multiSelect,
      size: 250,
    }),
    columnHelper.accessor((row) => row.product_count, {
      id: "product_count",
      footer: headers.product_count,
      header: ({column}) => <ColumnHeader title={headers.product_count} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.multiSelect,
      size: 250,
    })
  ]
}
