import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import {selectionColumn} from "@components/data-grid/selection-column"
import {TableActions} from "@components/tables/components/table-layout"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"

export type TListings = {
  selection: string
  id: string
  country: string
  country_shortcode: string
  currency: string
}

export type THeaders = {
  selection: string
  id: string
  country: string
  country_shortcode: string
  currency: string
}

const columnHelper = createColumnHelper<TListings>()

export const getColumns = (headers: THeaders, actions: TableActions) => {
  return [
    selectionColumn<TListings>((row) => row.id, {
      checkboxFieldName: "select-checkbox",
      editButton: true,
      delete_button: true,
      duplicateButton: false,
      actions,
    }),

    columnHelper.accessor((row) => row.country, {
      id: "country",
      footer: headers.country,
      header: ({column}) => <ColumnHeader title={headers.country} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.multiSelect,
    }),
    columnHelper.accessor((row) => row.country_shortcode, {
      id: "country_shortcode",
      footer: headers.country_shortcode,
      header: ({column}) => <ColumnHeader title={headers.country_shortcode} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.multiSelect,
    }),
    columnHelper.accessor((row) => row.currency, {
      id: "currency",
      footer: headers.currency,
      header: ({column}) => <ColumnHeader title={headers.currency} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.multiSelect,
    }),
  ]
}
