import {SlimSelectElement} from "js/slim-select/slim-select"

export {}

type DemandPerson = {
  phone?: string
  mobile?: string
}

document.querySelectorAll<HTMLElement>(".f-demands__person-fieldset").forEach((fieldset) => {
  const baseApiUrl = fieldset.getAttribute("data-api")

  const loaders = fieldset.querySelectorAll<SlimSelectElement>(".js-demand-person--loader select")
  const hiddenInput = fieldset.querySelector<HTMLInputElement>(".js-demand-person__id")

  const phoneInput = fieldset.querySelector<HTMLInputElement>(".js-demand-person__phone input")
  const mobileInput = fieldset.querySelector<HTMLInputElement>(".js-demand-person__mobile input")

  const setSelected = (value: string) => {
    loaders.forEach((loader) => {
      if (!loader.classList.contains("--setter")) loader.slim.setSelected(value, false)
    })
  }

  const handleFetch = async (value: string) => {
    if (!baseApiUrl) return

    const response = await fetch(`${baseApiUrl}?id=${value}`)

    if (!response.ok) return null

    const data = (await response.json()) as DemandPerson

    console.log(data)

    if (phoneInput && data.phone) phoneInput.value = data.phone
    if (mobileInput && data.mobile) mobileInput.value = data.mobile
  }

  loaders.forEach((loader) => {
    loader.slim.render.callbacks.afterChange = () => {
      const selected = loader.slim.getSelected().find(Boolean)
      loader.classList.add("--setter")
      setSelected(selected)
      if (hiddenInput) {
        hiddenInput.value = selected
        hiddenInput.dispatchEvent(new Event("change"))
      }
      handleFetch(selected)
      loader.classList.remove("--setter")
    }

    loader.slim.render.enable() // I guess update slim in order to enable added callback
  })
})
