import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"
import SwitchVisual from "@components/tables/components/switch-visual"
import {selectionColumn} from "@components/data-grid/selection-column"
import {TableActions} from "@components/tables/components/table-layout"

export type TListings = {
  selection: string
  name: string,
  code: string,
  classed: string,
  id: string,
  in_pricelist: string
}

export type THeaders = {
  selection: string
  name: string,
  code: string,
  classed: string,
}

const columnHelper = createColumnHelper<TListings>()

export const getColumns = (headers: THeaders, actions: TableActions) => {
  return [
    selectionColumn<TListings>((row) => row.id, {
      checkboxFieldName: "select-checkbox",
      editButton: false,
      delete_button: false,
      duplicateButton: false,
      actions,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      footer: headers.name,
      header: ({column}) => <ColumnHeader title={headers.name} column={column} />,
      enableColumnFilter: true,
      size: 300,
    }),
    columnHelper.accessor((row) => row.code, {
      id: "code",
      footer: headers.code,
      header: ({column}) => <ColumnHeader title={headers.code} column={column} />,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.classed, {
      id: "classed",
      footer: headers.classed,
      header: ({column}) => <ColumnHeader title={headers.classed} column={column} />,
      enableColumnFilter: true,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      size: 180,
    }),
  ]
}
