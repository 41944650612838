import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper, ColumnDef} from "@tanstack/react-table"

export type TDocuments = {
  id: string
  demandItem: string
  selection: string
  creationDate: string
  itemStatus: string
  linkedItem: string
  type: string
  warehouse: string
  quantity: string
  priceCZK: string
  sideCosts: string
  totalPrice: string
  stockQuantity: string
  code: string
  name: string
  reservation: string
  transport: string
  offerDate: string
  offerNumber: string
  offerRound: string
  demandItemUrl: string
}

export type THeaders = {
  id: string
  selection: string
  demandItem: string
  creationDate: string
  itemStatus: string
  linkedItem: string
  type: string
  warehouse: string
  quantity: string
  priceCZK: string
  sideCosts: string
  totalPrice: string
  stockQuantity: string
  code: string
  name: string
  reservation: string
  transport: string
  offerDate: string
  offerNumber: string
  offerRound: string
  demandItemUrl: string
}

const columnHelper = createColumnHelper<TDocuments>()

export const getColumns = (headers: THeaders) => {
  const columns: ColumnDef<TDocuments, string>[] = []

  if (headers.demandItem) {
    columns.push(
      columnHelper.accessor((row) => row.demandItem, {
        id: "demandItem",
        footer: headers.demandItem,
        header: ({column}) => <ColumnHeader title={headers.demandItem} column={column} />,
        cell: (props) => <a href={props.row.original.demandItemUrl}>{props.getValue()}</a>,
      }),
    )
  }

  if (headers.creationDate) {
    columns.push(
      columnHelper.accessor((row) => row.creationDate, {
        id: "creationDate",
        footer: headers.creationDate,
        header: ({column}) => <ColumnHeader title={headers.creationDate} column={column} />,
        cell: (props) => {
          return new Date(props.getValue()).toLocaleDateString("cs")
        },
      }),
    )
  }

  if (headers.itemStatus) {
    columns.push(
      columnHelper.accessor((row) => row.itemStatus, {
        id: "itemStatus",
        footer: headers.itemStatus,
        header: ({column}) => <ColumnHeader title={headers.itemStatus} column={column} />,
      }),
    )
  }

  if (headers.linkedItem) {
    columns.push(
      columnHelper.accessor((row) => row.linkedItem, {
        id: "linkedItem",
        footer: headers.linkedItem,
        header: ({column}) => <ColumnHeader title={headers.linkedItem} column={column} />,
      }),
    )
  }

  if (headers.type) {
    columns.push(
      columnHelper.accessor((row) => row.type, {
        id: "type",
        footer: headers.type,
        header: ({column}) => <ColumnHeader title={headers.type} column={column} />,
      }),
    )
  }

  if (headers.warehouse) {
    columns.push(
      columnHelper.accessor((row) => row.warehouse, {
        id: "warehouse",
        footer: headers.warehouse,
        header: ({column}) => <ColumnHeader title={headers.warehouse} column={column} />,
      }),
    )
  }

  if (headers.quantity) {
    columns.push(
      columnHelper.accessor((row) => row.quantity, {
        id: "quantity",
        footer: headers.quantity,
        header: ({column}) => <ColumnHeader title={headers.quantity} column={column} />,
      }),
    )
  }

  if (headers.priceCZK) {
    columns.push(
      columnHelper.accessor((row) => row.priceCZK, {
        id: "priceCZK",
        footer: headers.priceCZK,
        header: ({column}) => <ColumnHeader title={headers.priceCZK} column={column} />,
      }),
    )
  }

  if (headers.sideCosts) {
    columns.push(
      columnHelper.accessor((row) => row.sideCosts, {
        id: "sideCosts",
        footer: headers.sideCosts,
        header: ({column}) => <ColumnHeader title={headers.sideCosts} column={column} />,
      }),
    )
  }

  if (headers.totalPrice) {
    columns.push(
      columnHelper.accessor((row) => row.totalPrice, {
        id: "totalPrice",
        footer: headers.totalPrice,
        header: ({column}) => <ColumnHeader title={headers.totalPrice} column={column} />,
      }),
    )
  }

  if (headers.stockQuantity) {
    columns.push(
      columnHelper.accessor((row) => row.stockQuantity, {
        id: "stockQuantity",
        footer: headers.stockQuantity,
        header: ({column}) => <ColumnHeader title={headers.stockQuantity} column={column} />,
      }),
    )
  }

  if (headers.code) {
    columns.push(
      columnHelper.accessor((row) => row.code, {
        id: "code",
        footer: headers.code,
        header: ({column}) => <ColumnHeader title={headers.code} column={column} />,
      }),
    )
  }

  if (headers.name) {
    columns.push(
      columnHelper.accessor((row) => row.name, {
        id: "name",
        footer: headers.name,
        header: ({column}) => <ColumnHeader title={headers.name} column={column} />,
      }),
    )
  }

  if (headers.reservation) {
    columns.push(
      columnHelper.accessor((row) => row.reservation, {
        id: "reservation",
        footer: headers.reservation,
        cell: (props) => {
          return new Date(props.getValue()).toLocaleDateString("cs")
        },
        header: ({column}) => <ColumnHeader title={headers.reservation} column={column} />,
      }),
    )
  }

  if (headers.transport) {
    columns.push(
      columnHelper.accessor((row) => row.transport, {
        id: "transport",
        footer: headers.transport,
        header: ({column}) => <ColumnHeader title={headers.transport} column={column} />,
      }),
    )
  }

  if (headers.offerDate) {
    columns.push(
      columnHelper.accessor((row) => row.offerDate, {
        id: "offerDate",
        footer: headers.offerDate,
        cell: (props) => {
          return new Date(props.getValue()).toLocaleDateString("cs")
        },
        header: ({column}) => <ColumnHeader title={headers.offerDate} column={column} />,
      }),
    )
  }

  if (headers.offerNumber) {
    columns.push(
      columnHelper.accessor((row) => row.offerNumber, {
        id: "offerNumber",
        footer: headers.offerNumber,
        header: ({column}) => <ColumnHeader title={headers.offerNumber} column={column} />,
      }),
    )
  }

  if (headers.offerRound) {
    columns.push(
      columnHelper.accessor((row) => row.offerRound, {
        id: "offerRound",
        footer: headers.offerRound,
        header: ({column}) => <ColumnHeader title={headers.offerRound} column={column} />,
      }),
    )
  }

  return columns
}
