import {SlimSelectElement} from "js/slim-select/slim-select"
import {Option, OptionOptional} from "slim-select/src/slim-select/store"

export {}

type Addresses = {
  items: OptionOptional[]
}

const addressesSelect = document.querySelector<HTMLElement>(".js-person-form-info-address")
const addressesSelectInput = addressesSelect?.querySelector<SlimSelectElement>("select")
const apiBaseUrl = addressesSelect?.getAttribute("data-api")
const companiesSelectInput = document.querySelector<SlimSelectElement>(".js-person-form-info-companies select")

if (addressesSelect && companiesSelectInput && apiBaseUrl && addressesSelectInput) {
  const getApiUrl = (values: string[]) => {
    if (!apiBaseUrl) return

    return `${apiBaseUrl}?companies=${values.join(",")}`
  }

  const setAddress = (data: OptionOptional[]) => {
    addressesSelectInput.slim.setData(data)
  }

  const hadleFetch = async (values: string[]) => {
    const apiUrl = getApiUrl(values)
    if (!apiUrl) return

    const response = await fetch(apiUrl)
    if (!response.ok) return null
    const data = (await response.json()) as Addresses

    console.log(data)

    setAddress(data.items)
  }
  companiesSelectInput.addEventListener("change", () => {
    const values = companiesSelectInput.slim
      .getData()
      .filter((option: Option) => option.selected)
      .map((option: Option) => option.value)

    addressesSelect?.classList.add("--loading")
    hadleFetch(values)
    addressesSelect?.classList.remove("--loading")
  })
}
