export {}

const outerTabsGroup = document.querySelectorAll(".js-tabs")

outerTabsGroup.forEach((tabGroup) => {
  const navItems = tabGroup.querySelectorAll(".js-tabs__nav-item.--button")
  const panels = tabGroup.querySelectorAll(".js-tabs__panel")

  const closeAllPanels = () => panels.forEach((content) => content.classList.remove("--active"))
  const resetAllTabItems = () => navItems.forEach((link) => link.classList.remove("--active"))

  navItems.forEach((navItem) => {
    navItem.addEventListener("click", (event) => {
      event.preventDefault()

      if (navItem.classList.contains("--active")) return

      const targetId = navItem.getAttribute("data-panel-target") ?? ""
      const targetPanel = tabGroup.querySelector(`[data-panel-id="${targetId}"]`)

      if (targetPanel === null) return

      closeAllPanels()
      resetAllTabItems()
      targetPanel.classList.add("--active")
      navItem.classList.add("--active")
    })
  })
})

const innerTabsGroup = document.querySelectorAll(".js-inner-tabs")

innerTabsGroup.forEach((tabGroup) => {
  const navItems = tabGroup.querySelectorAll(".js-inner-tabs__nav-item")
  const panels = tabGroup.querySelectorAll(".js-inner-tabs__panel")

  const closeAllPanels = () => panels.forEach((content) => content.classList.remove("--active"))
  const resetAllTabItems = () => navItems.forEach((link) => link.classList.remove("--active"))

  navItems.forEach((navItem) => {
    navItem.addEventListener("click", (event) => {
      event.preventDefault()

      if (navItem.classList.contains("--active")) return

      const targetId = navItem.getAttribute("data-panel-target") ?? ""
      const targetPanel = tabGroup.querySelector(`[data-panel-id="${targetId}"]`)

      if (targetPanel === null) return

      closeAllPanels()
      resetAllTabItems()
      targetPanel.classList.add("--active")
      navItem.classList.add("--active")
    })
  })
})
