// column-header.tsx
import React, {useState, useEffect} from "react"
import {Column} from "@tanstack/react-table"
import {SortToggler} from "./sort-toggler/sort-toggler"
import clsx from "clsx"

interface ColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>
  title: string
  align?: "right" | "left"
}

export function ColumnHeader<TData, TValue>({column, title, align}: ColumnHeaderProps<TData, TValue>) {
  const [isSorted, setIsSorted] = useState(false)

  useEffect(() => {
    setIsSorted(column.getIsSorted() !== false)
  }, [column.getIsSorted()])

  const classNames = clsx(
    `d-flex gap-1 mb-1`,
    column.getCanSort() && "u-cursor-pointer",
    isSorted && "sort-toggler__sorted",
    "text-right",
    align === "right" && "justify-content-end",
  )

  const handleToggleSorting = () => {
    column.toggleSorting()
    setIsSorted(column.getIsSorted() !== false)
  }

  return (
    // renders the title and sort icon for each column; does not affect the rendering of the 'selection' column, searchable dropdown or column resize handler
    <div
      onClick={handleToggleSorting}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleToggleSorting()
        }
      }}
      className={classNames}
      tabIndex={0}
    >
      {title}
      {column.getCanSort() && <SortToggler column={column} />}
    </div>
  )
}
