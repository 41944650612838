import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"
import {selectionColumn} from "@components/data-grid/selection-column"
import {TableActions} from "@components/tables/components/table-layout"

export type TDocuments = {
  id: string
  name: string
  selection: string
  document_name: string
  uploaded_date: string
  username: string
  note: string
}

export type THeaders = {
  id: string
  selection: string
  name: string
  document_name: string
  uploaded_date: string
  username: string
  note: string
}

const columnHelper = createColumnHelper<TDocuments>()

export const getColumns = (headers: THeaders, actions: TableActions) => {
  return [
    selectionColumn<TDocuments>((row) => row.id, {
      checkboxFieldName: "select-checkbox",
      editButton: true,
      modalTitle: "Opravdu si přejete smazat vybrané položky?",
      duplicateButton: false,
      delete_button: true,
      actions,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      footer: headers.name,
      header: ({column}) => <ColumnHeader title={headers.name} column={column} />,
    }),
    columnHelper.accessor((row) => row.document_name, {
      id: "document_name",
      header: ({column}) => <ColumnHeader title={headers.document_name} column={column} />,
      footer: headers.document_name,
    }),
    columnHelper.accessor((row) => row.uploaded_date, {
      id: "uploaded_date",
      footer: headers.uploaded_date,
      header: ({column}) => <ColumnHeader title={headers.uploaded_date} column={column} />,
      filterFn: customFilterFns.inDateRange,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
    }),
    columnHelper.accessor((row) => row.username, {
      id: "username",
      header: ({column}) => <ColumnHeader title={headers.username} column={column} />,
      footer: headers.username,
    }),
    columnHelper.accessor((row) => row.note, {
      id: "note",
      header: ({column}) => <ColumnHeader title={headers.note} column={column} />,
      footer: headers.note,
    }),
  ]
}
