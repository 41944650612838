import React from "react"
import {Tooltip as ReactTooltip} from "react-tooltip"
interface TooltipTruncatedProps {
  id: string
  text: string
}

const TooltipTruncated: React.FC<TooltipTruncatedProps> = ({id, text}) => {
  return (
    <ReactTooltip
      id={id}
      className="tooltip-truncated"
      place="top-start"
      content={text}
      style={{
        backgroundColor: "#476cfe",
      }}
    />
  )
}

export default TooltipTruncated
