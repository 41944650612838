export {}

const mainSidebar = document.querySelector(".m-sidebar")
const navItems = document.querySelectorAll(".i-main-nav")

const togglers = document.querySelectorAll(".--js-nav-toggler")
const openers = document.querySelectorAll(".--js-nav-opener")
const closers = document.querySelectorAll(".--js-nav-closer")

export const mobileQuery = window.matchMedia("(max-width: 61.999em)") // Change to your needs

const isCollapsed = () => !!mainSidebar?.classList.contains("--compact")

const open = () => {
  mainSidebar?.classList.add("--compact")
  navItems.forEach((item) => item.classList.add("--compact"))
}

const close = () => {
  mainSidebar?.classList.remove("--compact")
  navItems.forEach((item) => item.classList.remove("--compact"))
}

const toggle = () => (isCollapsed() ? close() : open())

closers.forEach((closer) => closer.addEventListener("click", close))
openers.forEach((opener) => opener.addEventListener("click", open))
togglers.forEach((toggler) =>
  toggler.addEventListener("click", () => {
    toggle()
    toggler.classList.toggle("--closed", isCollapsed())
  }),
)

const handleMobileChange = (mediaQueryList: MediaQueryListEvent | MediaQueryList) => {
  if (!mediaQueryList.matches) return // its desktop
  open()
}

mobileQuery.addEventListener("change", handleMobileChange)
