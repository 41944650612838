const imageFields = document.querySelectorAll<HTMLElement>(".f-image")

imageFields.forEach((imageField) => {
  const input = imageField.querySelector<HTMLInputElement>(".f-image__input")
  const dropzone = imageField.querySelector<HTMLElement>(".f-image__dropzone")
  const img = imageField.querySelector<HTMLImageElement>(".f-image__img")
  const clearButton = imageField.querySelector(".f-image__clear")
  const dbDeleteInput = imageField.querySelector<HTMLInputElement>(".f-image__delete")

  if (!input) return

  const clearFile = () => {
    if (img) img.src = ""
    input.value = ""
    imageField.classList.remove("--filled")
  }

  const highlight = () => imageField.classList.add("--highlight")

  const unhighlight = () => imageField.classList.remove("--highlight")

  const handleDrop = (e: DragEvent) => {
    const dt = e.dataTransfer
    if (!dt) return
    input.files = dt.files
    handleChange(input)
  }
  const handleChange = (input: HTMLInputElement) => {
    const file = input.files?.[0] ?? null
    if (!file) return
    if (img) img.src = URL.createObjectURL(file)
    imageField.classList.add("--filled")
  }

  const preventDefaults = (e: Event) => {
    e.preventDefault()
    e.stopPropagation()
  }

  dropzone?.addEventListener("click", () => {
    input.click()
  })

  input.addEventListener("change", () => {
    handleChange(input)
  })

  clearButton?.addEventListener("click", (event: Event) => {
    event.preventDefault()
    clearFile()
    dbDeleteInput?.removeAttribute("disabled")
  })
  ;["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
    dropzone?.addEventListener(eventName, preventDefaults, false)
  })
  ;["dragenter", "dragover"].forEach((eventName) => {
    dropzone?.addEventListener(eventName, highlight, false)
  })
  ;["dragleave", "drop"].forEach((eventName) => {
    dropzone?.addEventListener(eventName, unhighlight, false)
  })
  dropzone?.addEventListener("drop", handleDrop, false)
})
