document.querySelectorAll(".f-demands__coverage-fieldset").forEach((fieldset) => {
  const bagWidth = fieldset.querySelector<HTMLInputElement>(".js-coverage-bag-width input")
  const bagHeight = fieldset.querySelector<HTMLInputElement>(".js-coverage-bag-height input")

  const printWidth = fieldset.querySelector<HTMLInputElement>(".js-coverage-print-width input")
  const printHeight = fieldset.querySelector<HTMLInputElement>(".js-coverage-print-height input")

  const coverageCalc = fieldset.querySelector<HTMLInputElement>(".js-coverage-calc input")

  if (!bagWidth || !bagHeight || !printWidth || !printHeight || !coverageCalc) return

  const inputs = [bagWidth, bagHeight, printWidth, printHeight]

  const isEveryValue = () => inputs.every((input) => input.value)

  const handleCalculation = () => {
    if (!isEveryValue()) {
      coverageCalc.value = ""
      return
    }

    const coverage = {
      bagWidth: Number(bagWidth.value),
      bagHeight: Number(bagHeight.value),
      printWidth: Number(printWidth.value),
      printHeight: Number(printHeight.value),
      printArea() {
        return this.printWidth * this.printHeight
      },
      bagArea() {
        return this.bagWidth * this.bagHeight
      },
      area() {
        return (this.printArea() / this.bagArea()) * 100
      },
      areaToString() {
        return String(this.area())
      },
    }

    coverageCalc.value = coverage.areaToString()
  }

  inputs.forEach((input) => {
    input.addEventListener("input", handleCalculation)
  })
})
