import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"

export type TDocuments = {
  id: string
  selection: string
  num_of_items: string
  base_price: string
  client_price: string
}

export type THeaders = {
  id: string
  selection: string
  num_of_items: string
  base_price: string
  client_price: string
}

const columnHelper = createColumnHelper<TDocuments>()

export const getColumns = (headers: THeaders) => {
  return [
    columnHelper.accessor((row) => row.num_of_items, {
      id: "num_of_items",
      footer: headers.num_of_items,
      header: ({column}) => <ColumnHeader title={headers.num_of_items} column={column} />,
    }),
    columnHelper.accessor((row) => row.base_price, {
      id: "base_price",
      header: ({column}) => <ColumnHeader title={headers.base_price} column={column} />,
      footer: headers.base_price,
    }),
    columnHelper.accessor((row) => row.client_price, {
      id: "client_price",
      header: ({column}) => <ColumnHeader title={headers.client_price} column={column} />,
      footer: headers.client_price,
    }),
  ]
}
