import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import SwitchVisual from "@components/tables/components/switch-visual"
import {selectionColumn} from "@components/data-grid/selection-column"
import {TableActions} from "@components/tables/components/table-layout"

export type TListings = {
  selection: string
  name: string,
  active: string,
  id: string
}

export type THeaders = {
  selection: string
  name: string,
  active: string,
  id: string
}

const columnHelper = createColumnHelper<TListings>()

export const getColumns = (headers: THeaders, actions: TableActions) => {
  return [
    selectionColumn<TListings>((row) => row.id, {
      checkboxFieldName: "select-checkbox",
      editButton: true,
      delete_button: false,
      duplicateButton: false,
      add_button: false,
      actions,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      footer: headers.name,
      header: ({column}) => <ColumnHeader title={headers.name} column={column} />,
      enableColumnFilter: true,
      size: 300,
    }),
    columnHelper.accessor((row) => row.active, {
      id: "active",
      footer: headers.active,
      header: ({column}) => <ColumnHeader title={headers.active} column={column} />,
      enableColumnFilter: true,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      size: 180,
    })
  ]
}
