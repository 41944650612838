import React from "react"
import {Column} from "@tanstack/react-table"
import {ReactSVG} from "react-svg"

interface SortTogglerProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  readonly column: Column<TData, TValue>
}

export function SortToggler<TData, TValue>({column}: SortTogglerProps<TData, TValue>) {
  const sortState = column.getIsSorted() as string
  let iconPath = "/images/svg/chevron-down.svg"
  if (sortState === "asc") {
    iconPath = "/images/svg/chevron-up.svg"
  }
  return (
    <span className={`sort-toggler d-flex justify-content-center align-items-center ${sortState}`}>
      <ReactSVG src={iconPath} className={sortState ? "sorted" : ""} />
    </span>
  )
}
