import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"

import {createColumnHelper} from "@tanstack/react-table"

export type TDocument = {
  id: string
  type: string
  date: string
  order: {
    id: string
    url: string
  }
  price: string
  currency: string
  url: string
}

export type THeaders = {
  id: string
  type: string
  date: string
  order: string
  price: string
  currency: string
}

const columnHelper = createColumnHelper<TDocument>()

export const getColumns = (headers: THeaders) => {
  return [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      header: ({column}) => <ColumnHeader title={headers.id} column={column} />,
      cell: (props) => <a href={props.row.original.url}>{props.getValue()}</a>,
      enableResizing: true,
    }),
    columnHelper.accessor((row) => row.type, {
      id: "type",
      header: ({column}) => <ColumnHeader title={headers.type} column={column} />,
      enableResizing: true,
    }),
    columnHelper.accessor((row) => row.date, {
      id: "date",
      header: ({column}) => <ColumnHeader title={headers.date} column={column} />,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
      enableResizing: true,
    }),
    columnHelper.accessor((row) => row.order.id, {
      id: "order_id",
      header: ({column}) => <ColumnHeader title={headers.order} column={column} />,
      cell: (props) => <a href={props.row.original.order.url}>{props.getValue()}</a>,
      enableResizing: true,
    }),
    columnHelper.accessor((row) => row.price, {
      id: "price",
      header: ({column}) => <ColumnHeader title={headers.price} column={column} />,
      enableResizing: true,
    }),
    columnHelper.accessor((row) => row.currency, {
      id: "currency",
      header: ({column}) => <ColumnHeader title={headers.currency} column={column} />,
      enableResizing: true,
    }),
  ]
}
