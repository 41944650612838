import {errorField} from "../error-field/error-field"

export {}

const fieldsWrapper = document.querySelectorAll<HTMLElement>(".f-text, .f-textarea")

const addActive = (inputField: HTMLElement) => {
  inputField.classList.add("--active")
}

const removeActive = (inputField: HTMLElement) => {
  inputField.classList.remove("--active")
}

const validateInput = (inputField: HTMLElement, input: HTMLInputElement) => {
  const errorFieldElement = inputField.querySelector<HTMLElement>(".f-error")
  const errorFieldInstance = new errorField(errorFieldElement)
  const validators = JSON.parse(input.dataset.validators || "[]")
  let errorMessage = ""
  validators.forEach((validator) => {
    if (validator.condition === "no-spaces" && input.value.includes(" ")) {
      errorMessage = validator.msg
    }
  })
  if (errorMessage) {
    inputField.classList.add("--invalid")
    errorFieldInstance.setTitle(errorMessage)
    errorFieldInstance.toggle(true)
  } else {
    inputField.classList.remove("--invalid")
    errorFieldInstance.setTitle("")
    errorFieldInstance.toggle(false)
  }
}

fieldsWrapper.forEach((inputField) => {
  const input = inputField.querySelector<HTMLInputElement>(".f-text__input, .f-textarea__input")

  if (!input) return

  input.addEventListener("focusout", () => {
    removeActive(inputField)
    validateInput(inputField, input)
  })
  input.addEventListener("focus", () => addActive(inputField))
})
