import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"

export type TDemands = {
  selection: string
  type: string
  date: string
  status: string
  source: string
  cod: string
  currency: string
  lastActivity: string
  id: string
  url: string
}

export type THeaders = {
  selection: string
  type: string
  date: string
  status: string
  source: string
  cod: string
  currency: string
  lastActivity: string
  id: string
  url: string
}
const columnHelper = createColumnHelper<TDemands>()

export const getColumns = (headers: THeaders) => {
  return [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      footer: headers.id,
      header: ({column}) => <ColumnHeader title={headers.id} column={column} />,
      cell: (props) => <a href={props.row.original.url}>{props.getValue()}</a>,
    }),
    columnHelper.accessor((row) => row.type, {
      id: "type",
      footer: headers.type,
      header: ({column}) => <ColumnHeader title={headers.type} column={column} />,
      size: 180,
    }),
    columnHelper.accessor((row) => row.date, {
      id: "date",
      footer: headers.date,
      header: ({column}) => <ColumnHeader title={headers.date} column={column} />,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      footer: headers.status,
      header: ({column}) => <ColumnHeader title={headers.status} column={column} />,
      size: 250,
    }),
    columnHelper.accessor((row) => row.source, {
      id: "source",
      footer: headers.source,
      header: ({column}) => <ColumnHeader title={headers.source} column={column} />,
      size: 280,
    }),
    columnHelper.accessor((row) => row.cod, {
      id: "cod",
      footer: headers.cod,
      header: ({column}) => <ColumnHeader title={headers.cod} column={column} />,
    }),
    columnHelper.accessor((row) => row.currency, {
      id: "currency",
      footer: headers.currency,
      header: ({column}) => <ColumnHeader title={headers.currency} column={column} />,
    }),
    columnHelper.accessor((row) => row.lastActivity, {
      id: "lastActivity",
      footer: headers.lastActivity,
      header: ({column}) => <ColumnHeader title={headers.lastActivity} column={column} />,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
    }),
  ]
}
