import React, { ChangeEvent } from 'react';
import { ReactSVG } from 'react-svg';

interface CheckboxFieldProps {
  type?: 'radio' | 'checkbox';
  disabled?: boolean;
  required?: boolean;
  extraClass?: string;
  id?: string;
  value?: string;
  checked?: boolean;
  name?: string;
  title?: string;
  count?: number;
  description?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  useMinusIcon?: boolean
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  type = 'checkbox',
  disabled = false,
  required = false,
  extraClass = '',
  id,
  value,
  checked = false,
  name,
  title,
  count,
  description,
  onChange,
  useMinusIcon = false
}) => {
  const className = `f-checkbox d-inline-flex u-posr ${extraClass} ${
    type === 'radio' ? '--radio' : '--checkbox'
  } ${disabled ? '--disabled' : ''} ${required ? '--required' : ''}`;

  const classNameInput = 'f-checkbox__input u-posa';

  return (
    <label className={className}>
      <input
        className={classNameInput}
        type={type}
        id={id}
        value={value}
        checked={checked}
        name={name}
        disabled={disabled}
        onChange={onChange}
        required={required}
      />
      <span className="f-checkbox__visual flex-shrink-0 d-flex align-items-center justify-content-center">
      {type === 'checkbox' && (checked && useMinusIcon ? <ReactSVG src="/images/svg/minus.svg" /> : <ReactSVG src="/images/svg/check.svg" />)}
      </span>
      <div className="f-checkbox__body d-flex flex-column">
        <span className="f-checkbox__title u-fz-sm u-fw-600">
          {title}
          {count && <span className="f-checkbox__count u-fw-400">({count})</span>}
        </span>
        {description && <div className="f-checkbox__description u-fz-xs">{description}</div>}
      </div>
    </label>
  );
};

export default CheckboxField;
