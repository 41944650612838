import {aresClient} from "./ares-modal--client"
import {Field, FieldTypes} from "./ares-modal--field"

export {}

const aresModals = document.querySelectorAll(".m-ares")
const openers = document.querySelectorAll(".js-ares-opener")

aresModals.forEach(async (modal) => {
  const updateButtons = modal.querySelectorAll(".m-ares__btn-update")
  const activateUpdateButtons = () => updateButtons.forEach((button) => button.classList.remove("--disabled"))
  const fields: Field[] = []

  fields.push(
    new Field(
      "company",
      document.querySelector<HTMLInputElement>(".js-ares-company input") ?? undefined,
      modal.querySelector<HTMLInputElement>(".m-ares__col.--before .m-ares__company") ?? undefined,
      modal.querySelector<HTMLInputElement>(".m-ares__col.--after .m-ares__company") ?? undefined,
    ),
    new Field(
      "ico",
      document.querySelector<HTMLInputElement>(".js-ares-ico input") ?? undefined,
      modal.querySelector<HTMLInputElement>(".m-ares__col.--before .m-ares__ico") ?? undefined,
      modal.querySelector<HTMLInputElement>(".m-ares__col.--after .m-ares__ico") ?? undefined,
    ),
    new Field(
      "vat",
      document.querySelector<HTMLInputElement>(".js-ares-vat input") ?? undefined,
      modal.querySelector<HTMLInputElement>(".m-ares__col.--before .m-ares__vat") ?? undefined,
      modal.querySelector<HTMLInputElement>(".m-ares__col.--after .m-ares__vat") ?? undefined,
    ),
    new Field(
      "street",
      document.querySelector<HTMLInputElement>(".js-ares-street input") ?? undefined,
      modal.querySelector<HTMLInputElement>(".m-ares__col.--before .m-ares__street") ?? undefined,
      modal.querySelector<HTMLInputElement>(".m-ares__col.--after .m-ares__street") ?? undefined,
    ),
    new Field(
      "city",
      document.querySelector<HTMLInputElement>(".js-ares-city input") ?? undefined,
      modal.querySelector<HTMLInputElement>(".m-ares__col.--before .m-ares__city") ?? undefined,
      modal.querySelector<HTMLInputElement>(".m-ares__col.--after .m-ares__city") ?? undefined,
    ),
    new Field(
      "zip",
      document.querySelector<HTMLInputElement>(".js-ares-zip input") ?? undefined,
      modal.querySelector<HTMLInputElement>(".m-ares__col.--before .m-ares__zip") ?? undefined,
      modal.querySelector<HTMLInputElement>(".m-ares__col.--after .m-ares__zip") ?? undefined,
    ),
  )

  const setBeforesByInputs = () => fields.forEach((field) => field.setBeforeByInput())
  const replaceInputsByAfter = () => fields.forEach((field) => field.replaceInputByAfter())

  const getFieldByType = (type: FieldTypes) => [...fields].find((field) => field.type === type)

  const icoField = getFieldByType("ico")

  modal.addEventListener("open", async () => {
    setBeforesByInputs()
    modal.querySelector(".m-ares__col.--after")?.classList.add("--loading")

    const data = await aresClient.searchByIco(icoField?.tryGetInputValue() ?? "")

    if (data) {
      console.log(data)

      icoField?.setAfter(data.reg_num)
      icoField?.handleSame()

      const cityField = getFieldByType("city")
      cityField?.setAfter(data.city)
      cityField?.handleSame()

      const streetField = getFieldByType("street")
      streetField?.setAfter(data.street)
      streetField?.handleSame()

      const zipField = getFieldByType("zip")
      zipField?.setAfter(data.zipcode)
      zipField?.handleSame()

      const companyField = getFieldByType("company")
      companyField?.setAfter(data.name)
      companyField?.handleSame()

      const vatField = getFieldByType("vat")
      vatField?.setAfter(data.vat_num)
      vatField?.handleSame()

      activateUpdateButtons()
    }

    if (!data) {
      modal.querySelector(".m-ares__error")?.classList.add("--show")
    }

    modal.querySelector(".m-ares__col.--after")?.classList.remove("--loading")
  })

  updateButtons.forEach((button) => {
    button.addEventListener("click", (event: Event) => {
      event.preventDefault()
      if (button.classList.contains("--disabled")) return
      replaceInputsByAfter()
    })
  })
})

openers.forEach((opener) => {
  const modalTarget = document.getElementById(opener.getAttribute("data-modal-target") ?? "")

  opener.addEventListener("click", () => {
    modalTarget?.dispatchEvent(new Event("open"))
  })
})
