import React from "react"
import ReactDOM from "react-dom/client"
import {DataTable} from "./demands-datatable"
import {TDemand, THeaders, getColumns} from "./demands-columns"
import {ButtonsActions} from "@components/tables/components/table-layout"
export {}

const tables = document.querySelectorAll<HTMLElement>(".company-demands-table")

type Table = {
  data: TDemand[]
  headers: THeaders
  buttons: ButtonsActions
}

tables.forEach((tableElement) => {
  const table = JSON.parse(tableElement.dataset.table ?? "null") as Table | null
  if (!table) return

  ReactDOM.createRoot(tableElement).render(
    <React.StrictMode>
      <DataTable data={table.data} columns={getColumns(table.headers)} buttons={table.buttons} />
    </React.StrictMode>,
  )
})
