import {SlimSelectElement} from "js/slim-select/slim-select"
import {Option} from "slim-select/src/slim-select/store"

const companiesSelect = document.querySelector<SlimSelectElement>(".js-person-form-info-companies select")
const primaryCompanySelect = document.querySelector<SlimSelectElement>(".js-person-form-info-primary-company select")

/**
 * just o be sure that Slim select is initialized
 * {@link js/slim-select/slim-select.ts}
 */
document.addEventListener("slimselectInit", () => {
  if (!companiesSelect || !primaryCompanySelect) return

  const primaryCompanyInitOptions = primaryCompanySelect.slim.getData() as Option[]

  const primaryCompanySelectInitData = primaryCompanyInitOptions.length ? [primaryCompanyInitOptions[0]] : null

  const handleCompanies = () => {
    const options = companiesSelect.slim
      .getData()
      .filter((option: Option) => option.selected)
      .map((option: Option) => ((option.selected = false), option))

    const primarySelected = primaryCompanySelect.slim.getSelected()[0]

    primaryCompanySelect.slim.setData(primaryCompanySelectInitData ? [...primaryCompanySelectInitData, ...options] : options)

    options.length ? primaryCompanySelect.slim.enable() : primaryCompanySelect.slim.disable()

    if (options.length) {
      primaryCompanySelect.slim.enable()
      const primaryCompanySelectData = primaryCompanySelect.slim.getData() as Option[]

      if (primaryCompanySelectData[1]) {
        primaryCompanySelect.slim.setSelected(primaryCompanySelectData[1].value)
      }
    } else {
      primaryCompanySelect.slim.disable()
    }

    if (primarySelected) {
      primaryCompanySelect.slim.setSelected(primarySelected)
    }
  }

  companiesSelect.addEventListener("change", () => {
    handleCompanies()
  })

  // On init
  handleCompanies()
})
