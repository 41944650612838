import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {ColumnDef, createColumnHelper} from "@tanstack/react-table"
import SwitchVisual from "@components/tables/components/switch-visual"
import {selectionColumn} from "@components/data-grid/selection-column"
import {TableActions} from "@components/tables/components/table-layout"

export type TListings = {
  selection: string
  delivery_group_name: string
  country_cz: string
  country_sk: string
  country_en: string
  country_de: string
  countries?: Record<string, string>
  group_name: string
  id: string
}

export type THeaders = {
  selection: string
  delivery_group_name: string
  country_cz: string
  country_sk: string
  country_en: string
  country_de: string
  countries?: Record<string, string>
  group_name: string
  id: string
}

const columnHelper = createColumnHelper<TListings>()

export const getColumns = (headers: THeaders, actions: TableActions) => {
  const columns: ColumnDef<TListings, string>[] = [
    selectionColumn<TListings>((row) => row.id, {
      checkboxFieldName: "select-checkbox",
      editButton: true,
      duplicateButton: false,
      delete_button: true,
      actions,
    }),
  ]

  if (headers.delivery_group_name) {
    columns.push(
      columnHelper.accessor((row) => row.delivery_group_name, {
        id: "delivery_group_name",
        footer: headers.delivery_group_name,
        header: ({column}) => <ColumnHeader title={headers.delivery_group_name} column={column} />,
        enableColumnFilter: true,
      }),
    )
  }

  if (headers.id) {
    columns.push(
      columnHelper.accessor((row) => row.id, {
        id: "id",
        footer: headers.id,
        header: ({column}) => <ColumnHeader title={headers.id} column={column} />,
        enableColumnFilter: true,
      }),
    )
  }

  if (headers.country_cz) {
    columns.push(
      columnHelper.accessor((row) => row.country_cz, {
        id: "country_cz",
        footer: headers.country_cz,
        header: ({column}) => <ColumnHeader title={headers.country_cz} column={column} />,
        cell: (props) => <SwitchVisual value={props.getValue()} />,
        enableColumnFilter: true,
      }),
    )
  }

  if (headers.country_sk) {
    columns.push(
      columnHelper.accessor((row) => row.country_sk, {
        id: "country_sk",
        footer: headers.country_sk,
        header: ({column}) => <ColumnHeader title={headers.country_sk} column={column} />,
        cell: (props) => <SwitchVisual value={props.getValue()} />,
        enableColumnFilter: true,
      }),
    )
  }

  if (headers.country_en) {
    columns.push(
      columnHelper.accessor((row) => row.country_en, {
        id: "country_en",
        footer: headers.country_en,
        header: ({column}) => <ColumnHeader title={headers.country_en} column={column} />,
        cell: (props) => <SwitchVisual value={props.getValue()} />,
        enableColumnFilter: true,
      }),
    )
  }

  if (headers.country_de) {
    columns.push(
      columnHelper.accessor((row) => row.country_de, {
        id: "country_de",
        footer: headers.country_de,
        header: ({column}) => <ColumnHeader title={headers.country_de} column={column} />,
        cell: (props) => <SwitchVisual value={props.getValue()} />,
        enableColumnFilter: true,
      }),
    )
  }

  if (headers.countries) {
    Object.entries(headers.countries).forEach(([key, value]) => {
      columns.push(
        columnHelper.accessor((row) => row.countries[key], {
          id: `country-${key}`,
          footer: value,
          header: ({column}) => <ColumnHeader title={value} column={column} />,
          cell: (props) => <SwitchVisual value={props.getValue()} />,
          enableColumnFilter: true,
        }),
      )
    })
  }

  return columns
}
