import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"

import {createColumnHelper} from "@tanstack/react-table"

export type TDemand = {
  url: string
  id: string
  date: string
  source: string
  type: string
  company: string
  name: string
  status: string
  lastActivity: string
  worker: string
}

export type THeaders = {
  id: string
  date: string
  source: string
  type: string
  company: string
  name: string
  status: string
  lastActivity: string
  worker: string
}

const columnHelper = createColumnHelper<TDemand>()

export const getColumns = (headers: THeaders) => {
  return [
    columnHelper.accessor((row) => row.id, {
      id: "id",
      header: ({column}) => <ColumnHeader title={headers.id} column={column} />,
      cell: (props) => <a href={props.row.original.url}>{props.getValue()}</a>,
      enableResizing: true,
    }),
    columnHelper.accessor((row) => row.date, {
      id: "date",
      header: ({column}) => <ColumnHeader title={headers.date} column={column} />,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
      enableResizing: true,
    }),
    columnHelper.accessor((row) => row.source, {
      id: "source",
      header: ({column}) => <ColumnHeader title={headers.source} column={column} />,
      enableResizing: true,
    }),
    columnHelper.accessor((row) => row.type, {
      id: "type",
      header: ({column}) => <ColumnHeader title={headers.type} column={column} />,
      enableResizing: true,
    }),
    columnHelper.accessor((row) => row.company, {
      id: "company",
      header: ({column}) => <ColumnHeader title={headers.company} column={column} />,
      enableResizing: true,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: ({column}) => <ColumnHeader title={headers.name} column={column} />,
      enableResizing: true,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      header: ({column}) => <ColumnHeader title={headers.status} column={column} />,
      enableResizing: true,
    }),
    columnHelper.accessor((row) => row.lastActivity, {
      id: "lastActivity",
      header: ({column}) => <ColumnHeader title={headers.lastActivity} column={column} />,
      cell: (props) => {
        return new Date(props.getValue()).toLocaleDateString("cs")
      },
      enableResizing: true,
    }),
    columnHelper.accessor((row) => row.worker, {
      id: "worker",
      header: ({column}) => <ColumnHeader title={headers.worker} column={column} />,
      enableResizing: true,
    }),
  ]
}
