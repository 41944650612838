import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"
import SwitchVisual from "@components/tables/components/switch-visual"
import {selectionColumn} from "@components/data-grid/selection-column"
import {TableActions} from "@components/tables/components/table-layout"

export type TListings = {
  selection: string
  contact_person: string
  country: string
  email: string
  company_name: string
  ic_number: string
  phone_number: string
  city: string
  gdpr_consent: string
  newsletter_consent: string
  id: string
  url: string
}

export type THeaders = {
  selection: string
  contact_person: string
  country: string
  email: string
  company_name: string
  ic_number: string
  phone_number: string
  city: string
  gdpr_consent: string
  newsletter_consent: string
  id: string
  url: string
}

const columnHelper = createColumnHelper<TListings>()

export const getColumns = (headers: THeaders, actions: TableActions) => {
  return [
    selectionColumn<TListings>((row) => row.id, {
      checkboxFieldName: "select-checkbox",
      editButton: true,
      duplicateButton: true,
      delete_button: true,
      actions,
    }),
    columnHelper.accessor((row) => row.contact_person, {
      id: "contact_person",
      footer: headers.contact_person,
      header: ({column}) => <ColumnHeader title={headers.contact_person} column={column} />,
      enableColumnFilter: true,
      size: 180,
    }),
    columnHelper.accessor((row) => row.country, {
      id: "country",
      footer: headers.country,
      header: ({column}) => <ColumnHeader title={headers.country} column={column} />,
      filterFn: customFilterFns.multiSelect,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.email, {
      id: "email",
      footer: headers.email,
      header: ({column}) => <ColumnHeader title={headers.email} column={column} />,
      enableColumnFilter: true,
      size: 280,
    }),
    columnHelper.accessor((row) => row.company_name, {
      id: "company_name",
      footer: headers.company_name,
      header: ({column}) => <ColumnHeader title={headers.company_name} column={column} />,
      enableColumnFilter: true,
    }),

    columnHelper.accessor((row) => row.ic_number, {
      id: "ic_number",
      footer: headers.ic_number,
      header: ({column}) => <ColumnHeader title={headers.ic_number} column={column} />,
      filterFn: customFilterFns.multiSelect,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.phone_number, {
      id: "phone_number",
      footer: headers.phone_number,
      header: ({column}) => <ColumnHeader title={headers.phone_number} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),

    columnHelper.accessor((row) => row.city, {
      id: "city",
      footer: headers.city,
      header: ({column}) => <ColumnHeader title={headers.city} column={column} />,
      enableColumnFilter: true,
      size: 200,
    }),

    columnHelper.accessor((row) => row.gdpr_consent, {
      id: "gdpr_consent",
      footer: headers.gdpr_consent,
      header: ({column}) => <ColumnHeader title={headers.gdpr_consent} column={column} />,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      enableColumnFilter: true,
      size: 180,
    }),
    columnHelper.accessor((row) => row.newsletter_consent, {
      id: "newsletter_consent",
      footer: headers.newsletter_consent,
      header: ({column}) => <ColumnHeader title={headers.newsletter_consent} column={column} />,
      enableColumnFilter: true,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      size: 180,
    }),
    columnHelper.accessor((row) => row.id, {
      id: "id",
      footer: headers.id,
      header: ({column}) => <ColumnHeader title={headers.id} column={column} />,
      enableColumnFilter: true,
    }),
  ]
}
