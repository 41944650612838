import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"

import {createColumnHelper} from "@tanstack/react-table"

export type TAddress = {
  url: string
  type: string
  name: string
  company: string
  street: string
  city: string
  country: string
}

export type THeaders = {
  type: string
  name: string
  company: string
  street: string
  city: string
  country: string
}

const columnHelper = createColumnHelper<TAddress>()

export const getColumns = (headers: THeaders) => {
  return [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: ({column}) => <ColumnHeader title={headers.name} column={column} />,
      cell: (props) => <a href={props.row.original.url}>{props.getValue()}</a>,
    }),
    columnHelper.accessor((row) => row.type, {
      id: "type",
      header: ({column}) => <ColumnHeader title={headers.type} column={column} />,
    }),
    columnHelper.accessor((row) => row.company, {
      id: "company",
      header: ({column}) => <ColumnHeader title={headers.company} column={column} />,
    }),
    columnHelper.accessor((row) => row.street, {
      id: "street",
      header: ({column}) => <ColumnHeader title={headers.street} column={column} />,
    }),
    columnHelper.accessor((row) => row.city, {
      id: "city",
      header: ({column}) => <ColumnHeader title={headers.city} column={column} />,
    }),
    columnHelper.accessor((row) => row.country, {
      id: "country",
      header: ({column}) => <ColumnHeader title={headers.country} column={column} />,
    }),
  ]
}
