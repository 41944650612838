export {}

const mainSidebar = document.querySelector(".m-sidebar")

const overlay = document.querySelector(".m-sidebar__overlay")

export const mobileQuery = window.matchMedia("(max-width: 47.999em)") // Change to your needs

const closers = document.querySelectorAll(".--js-main-sidebar-closer")
const openers = document.querySelectorAll(".--js-main-sidebar-opener")

export const closeMenu = (event?: Event) => {
  event?.preventDefault()
  mainSidebar?.classList.remove("--open")
  overlay?.classList.remove("--show")
  document.body.style.setProperty("overflow", null)
}

const openMenu = (event: Event) => {
  event.preventDefault()
  mainSidebar?.classList.add("--open")
  mainSidebar?.classList.add("--transition")
  overlay?.classList.add("--show")
  document.body.style.setProperty("overflow", "hidden")
}

export const removeTrainsition = () => mainSidebar?.classList.remove("--transition")

if (!mainSidebar) {
  openers.forEach((opener) => {
    opener.remove()
  })
}

closers.forEach((closer) => {
  closer.addEventListener("click", closeMenu)
})
openers.forEach((opener) => {
  opener.addEventListener("click", openMenu)
})
