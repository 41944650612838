import Picker from "vanilla-picker"
import "vanilla-picker/dist/vanilla-picker.csp.css"

const colorFields = document.querySelectorAll<HTMLElement>(".f-color")

colorFields.forEach((colorField) => {
  const appWrapper = colorField.querySelector<HTMLElement>(".f-color__app")
  const input = colorField.querySelector<HTMLInputElement>(".f-color__input input")

  if (!appWrapper) return

  const colorPicker = new Picker({
    parent: appWrapper,
    popup: false,
    alpha: true,
    editor: false,
    color: colorField.dataset.color ?? undefined,
    onChange: (color) => {
      if (input) input.value = color.hex
    },
  })

  input?.addEventListener("input", () => {
    colorPicker.setColor(input.value, true)
  })
})
