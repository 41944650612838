import "./shopper"
import "./company"
import "./person"
import "./demander"
import "./addresses"
import "./admin"
import "./permissions"
import "./transport"
import "./payments"
import "./products"
import "./transport-group"
import "./demands"
import "./countries-currencies"
import "./logs-table"
import "./pricelist"
import "./machine"
import "./parameters"
