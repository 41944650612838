import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"
import SwitchVisual from "@components/tables/components/switch-visual"
import {selectionColumn} from "@components/data-grid/selection-column"
import TooltipTruncated from "@components/tables/components/tooltip-truncated"
import {TableActions} from "@components/tables/components/table-layout"

export type TListings = {
  selection: string
  id: string
  url: string
  first_name: string
  last_name: string
  email: string
  phone_number: string
  mobile: string
  primary_company: string
  address: string
  role: string
  job_position: string
  note: string
  gdpr_consent: string
  newsletter: string
  marketing_field: string
  access_control: string
}

export type THeaders = {
  selection: string
  id: string
  url: string
  first_name: string
  last_name: string
  email: string
  phone_number: string
  mobile: string
  primary_company: string
  address: string
  role: string
  job_position: string
  note: string
  gdpr_consent: string
  newsletter: string
  marketing_field: string
  access_control: string
}

const columnHelper = createColumnHelper<TListings>()

export const getColumns = (headers: THeaders, actions: TableActions) => {
  return [
    selectionColumn<TListings>((row) => row.id, {
      checkboxFieldName: "select-checkbox",
      editButton: true,
      duplicateButton: true,
      delete_button: true,
      actions,
    }),
    columnHelper.accessor((row) => row.id, {
      id: "id",
      footer: headers.id,
      header: ({column}) => <ColumnHeader title={headers.id} column={column} />,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.first_name, {
      id: "first_name",
      footer: headers.first_name,
      header: ({column}) => <ColumnHeader title={headers.first_name} column={column} />,
      enableColumnFilter: true,
      size: 200,
    }),
    columnHelper.accessor((row) => row.last_name, {
      id: "last_name",
      footer: headers.last_name,
      header: ({column}) => <ColumnHeader title={headers.last_name} column={column} />,
      enableColumnFilter: true,
      size: 200,
    }),
    columnHelper.accessor((row) => row.email, {
      id: "email",
      footer: headers.email,
      header: ({column}) => <ColumnHeader title={headers.email} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.phone_number, {
      id: "phone_number",
      footer: headers.phone_number,
      header: ({column}) => <ColumnHeader title={headers.phone_number} column={column} />,
      enableColumnFilter: true,
      size: 200,
    }),
    columnHelper.accessor((row) => row.mobile, {
      id: "mobile",
      footer: headers.mobile,
      header: ({column}) => <ColumnHeader title={headers.mobile} column={column} />,
      enableColumnFilter: true,
      size: 200,
    }),

    columnHelper.accessor((row) => row.primary_company, {
      id: "primary_company",
      footer: headers.primary_company,
      header: ({column}) => <ColumnHeader title={headers.primary_company} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.multiSelect,
      size: 250,
    }),

    columnHelper.accessor((row) => row.address, {
      id: "address",
      footer: headers.address,
      header: ({column}) => <ColumnHeader title={headers.address} column={column} />,
      enableColumnFilter: true,
    }),

    columnHelper.accessor((row) => row.role, {
      id: "role",
      footer: headers.role,
      header: ({column}) => <ColumnHeader title={headers.role} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.multiSelect,
      size: 250,
    }),

    columnHelper.accessor((row) => row.job_position, {
      id: "job_position",
      footer: headers.job_position,
      header: ({column}) => <ColumnHeader title={headers.job_position} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.multiSelect,
      size: 250,
    }),
    columnHelper.accessor((row) => row.note, {
      id: "note",
      footer: headers.note,
      header: ({column}) => <ColumnHeader title={headers.note} column={column} />,
      cell: (props) => (
        <>
          <span data-tooltip-id="note-tooltip">{props.getValue()}</span>
          <TooltipTruncated id="note-tooltip" text={props.getValue()} />
        </>
      ),
      size: 300,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.gdpr_consent, {
      id: "gdpr_consent",
      footer: headers.gdpr_consent,
      header: ({column}) => <ColumnHeader title={headers.gdpr_consent} column={column} />,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      enableColumnFilter: true,
      size: 180,
    }),
    columnHelper.accessor((row) => row.newsletter, {
      id: "newsletter",
      footer: headers.newsletter,
      header: ({column}) => <ColumnHeader title={headers.newsletter} column={column} />,
      enableColumnFilter: true,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      size: 180,
    }),
    columnHelper.accessor((row) => row.marketing_field, {
      id: "marketing_field",
      footer: headers.marketing_field,
      header: ({column}) => <ColumnHeader title={headers.marketing_field} column={column} />,
      enableColumnFilter: true,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      size: 180,
    }),
    columnHelper.accessor((row) => row.access_control, {
      id: "access_control",
      footer: headers.access_control,
      header: ({column}) => <ColumnHeader title={headers.access_control} column={column} />,
      enableColumnFilter: true,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      size: 180,
    }),
  ]
}
