import { ColumnDef } from "@tanstack/react-table";

export function getColNames<TData, TValue>(columns: ColumnDef<TData, TValue>[]) {
  const columnNames = columns.reduce(
    (acc, column) => {
      if (column.id && typeof column.footer === "string") {
        acc[column.id] = column.footer;
      }
      return acc;
    },
    {} as Record<string, string>,
  );
  return columnNames;
}
