import {Button} from "@components/button/button"
import {PageSize} from "@components/data-grid/page-size"
import {DataGridPagination} from "@components/data-grid/pagination"
import {Table} from "@components/data-grid/table"
import DraggableColumnList from "@components/data-grid/draggable-column-list/draggable-column-list"
import {Table as Tantable, ColumnFiltersState} from "@tanstack/react-table"
import React, {useEffect, useState} from "react"
import {ColumnConfig} from "@components/data-grid/table.types"

export type ButtonsActions = {
  create?: {
    title?: string
    url: string
  }
  create_alt?: {
    title?: string
    url: string
  }
  update?: {
    title?: string
    url: string
  }
}

export type AggregateActions = Array<{
  title?: string
  url: string
}>

export type TableActions = {
  edit?: {
    title?: string
    url: string
  }
  duplicate?: {
    title?: string
    url: string
  }
  delete?: {
    title?: string
    url: string
  }
  add?: {
    title?: string
    url: string
  }
}

interface TableLayoutProps<TData> {
  table: Tantable<TData>
  hasCollapsible?: boolean
  setColumnOrder?: React.Dispatch<React.SetStateAction<string[]>>
  columnNames?: Record<string, string>
  selectedData?: TData[]
  hideColumnReordering?: boolean
  setColumnFilters?: React.Dispatch<React.SetStateAction<ColumnFiltersState>>
  columnConfig: ColumnConfig[]
  filterResetKey?: number
  setFilterResetKey?: React.Dispatch<React.SetStateAction<number>>
  hideFooterActions?: boolean
  columnFilters?: ColumnFiltersState
  fetch_data_url?: string
  buttons?: ButtonsActions
  extra_class?: string
  hidden_input_name?: string
}

function TableLayout<TData>({
  table,
  hasCollapsible,
  setColumnOrder,
  columnNames,
  columnConfig,
  selectedData,
  hideColumnReordering = false,
  filterResetKey,
  hideFooterActions = false,
  extra_class,
  setFilterResetKey,
  setColumnFilters,
  buttons,
  hidden_input_name,
  columnFilters,
}: TableLayoutProps<TData>) {
  const [isColumnListVisible, setIsColumnListVisible] = useState(false)
  const [inputValue, setInputValue] = useState("")

  const toggleColumnList = () => {
    setIsColumnListVisible(!isColumnListVisible)
  }

  useEffect(() => {
    const ids = selectedData?.map((data) => (data as {id: string | number}).id).join(", ") ?? ""
    setInputValue(ids)
  }, [selectedData])

  const resetFilters = () => {
    if (setColumnFilters) {
      setColumnFilters?.([])
      setFilterResetKey?.((prevKey) => prevKey + 1)
    }
  }

  return (
    <>
      <div className="d-flex justify-content-end gap-1 align-items-center table-actions__wrapper mt-2">
        {setColumnFilters && (columnFilters ?? []).length > 0 && (
          <Button
            title="Zrušit filtr"
            tag="button"
            type="button"
            onClick={resetFilters}
            variant="outline"
            iconBefore={true}
            icon={{
              src: "/images/svg/xmark.svg",
            }}
          />
        )}

        <div className="d-flex gap-1 align-items-center">
          {!hideColumnReordering && (
            <div className="dropdown-wrapper">
              <Button
                title="Sloupce"
                tag="button"
                type="button"
                variant="blank"
                iconOnly={true}
                iconBefore={true}
                icon={{
                  src: "/images/svg/columns.svg",
                }}
                onClick={toggleColumnList}
              />
              {hasCollapsible && isColumnListVisible && (
                <div className="draggable-column-list">
                  <DraggableColumnList setColumnOrder={setColumnOrder ?? (() => {})} columnNames={columnNames ?? {}} table={table} />
                </div>
              )}
            </div>
          )}

          {buttons?.create && (
            <Button
              title={buttons.create.title ?? "Vytvořit nový"}
              tag="a"
              url={buttons.create.url}
              iconBefore={true}
              icon={{
                src: "/images/svg/plus.svg",
              }}
            />
          )}

          {buttons?.create_alt && (
            <Button
              title={buttons.create_alt.title ?? "Vytvořit nový"}
              tag="a"
              url={buttons.create_alt.url}
              iconBefore={true}
              icon={{
                src: "/images/svg/plus.svg",
              }}
            />
          )}
          {buttons?.update && (
            <Button
              title={buttons.update.title ?? "Aktualizovat"}
              tag="button"
              type="button"
              variant="blank"
              iconBefore={true}
              icon={{
                src: "/images/svg/refresh.svg",
              }}
              onClick={() => {
                console.log(buttons.update?.url)
                // TODO: Call Fetch request from passed url and then refresh datagrid
              }}
            />
          )}
        </div>
      </div>

      <Table
        table={table}
        selectedData={selectedData}
        columnConfig={columnConfig}
        filterResetKey={filterResetKey}
        extra_class={extra_class}
      />
      {!hideFooterActions && (
        <div className="d-flex gap-2 align-items-center mt-4 flex-wrap">
          <PageSize table={table} />
          <DataGridPagination table={table} />
        </div>
      )}
    </>
  )
}

export default TableLayout
