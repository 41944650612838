import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"
import {createColumnHelper} from "@tanstack/react-table"
import {customFilterFns} from "@components/data-grid/table-filters/custom-filter-fns"
import SwitchVisual from "@components/tables/components/switch-visual"
import {selectionColumn} from "@components/data-grid/selection-column"
import {TableActions} from "@components/tables/components/table-layout"

export type TListings = {
  selection: string
  email: string
  first_name: string
  last_name: string
  permissions: string
  active: string
  company_name: string
  id: string
  url: string
}

export type THeaders = {
  selection: string
  email: string
  first_name: string
  last_name: string
  permissions: string
  active: string
  company_name: string
  id: string
  url: string
}

const columnHelper = createColumnHelper<TListings>()

export const getColumns = (headers: THeaders, actions: TableActions) => {
  return [
    selectionColumn<TListings>((row) => row.id, {
      checkboxFieldName: "select-checkbox",
      editButton: true,
      actions,
      delete_button: true,
      modalTitle: "Opravdu si přejete odstranit tohoto administrátora?",
      duplicateButton: true,
    }),
    columnHelper.accessor((row) => row.email, {
      id: "email",
      footer: headers.email,
      header: ({column}) => <ColumnHeader title={headers.email} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.first_name, {
      id: "first_name",
      footer: headers.first_name,
      header: ({column}) => <ColumnHeader title={headers.first_name} column={column} />,
      enableColumnFilter: true,
      size: 250,
    }),
    columnHelper.accessor((row) => row.last_name, {
      id: "last_name",
      footer: headers.last_name,
      header: ({column}) => <ColumnHeader title={headers.last_name} column={column} />,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.permissions, {
      id: "permissions",
      footer: headers.permissions,
      header: ({column}) => <ColumnHeader title={headers.permissions} column={column} />,
      enableColumnFilter: true,
      filterFn: customFilterFns.multiSelect,
    }),
    columnHelper.accessor((row) => row.active, {
      id: "active",
      footer: headers.active,
      header: ({column}) => <ColumnHeader title={headers.active} column={column} />,
      cell: (props) => <SwitchVisual value={props.getValue()} />,
      filterFn: customFilterFns.matchesBoolean,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.company_name, {
      id: "company_name",
      footer: headers.company_name,
      header: ({column}) => <ColumnHeader title={headers.company_name} column={column} />,
      enableColumnFilter: true,
      size: 200,
    }),
  ]
}
