export {}

const elementsExist = (): boolean => {
  return !!document.querySelector(".js-shopper-info-type select") && !!document.querySelector(".js-ares-ico input")
}

if (elementsExist()) {
  const typeSelectElement = document.querySelector(".js-shopper-info-type select") as HTMLSelectElement
  const icoInputElement = document.querySelector(".js-ares-ico input") as HTMLInputElement
  const icoLabelElement = document.querySelector(".js-ares-ico label") as HTMLLabelElement

  const updateIcoFieldRequirement = () => {
    if (!typeSelectElement || !icoInputElement) {
      return
    }
    const selectedOption = typeSelectElement.options[typeSelectElement.selectedIndex]
    const isDependentOnIco = selectedOption.classList.contains("--dependency-ico")

    if (icoLabelElement) {
      if (isDependentOnIco) {
        icoLabelElement.classList.add("--required")
        icoInputElement.required = true
      } else {
        icoLabelElement.classList.remove("--required")
        icoInputElement.required = false
      }
    }
  }

  updateIcoFieldRequirement()

  if (typeSelectElement) {
    typeSelectElement.addEventListener("change", updateIcoFieldRequirement)
  }
}
