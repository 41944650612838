import React from "react"
import {useDataTable} from "@components/data-grid/hooks/useDataTable"
import TableLayout from "@components/tables/components/table-layout"
import {getColNames} from "@components/tables/utils/getColNames"
import {DataTableProps} from "@components/data-grid/table.types"

export function DataTable<TData, TValue>({columns, data, buttons}: DataTableProps<TData, TValue>) {
  const {table, selectedData, setColumnOrder, setColumnFilters, filterResetKey, setFilterResetKey} = useDataTable({columns, data})

  const columnNames = getColNames(columns)

  const columnConfig = [
    {
      name: "document_type",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "document_number",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "linked_order",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "source",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "amount",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "currency",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "payment",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "status",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "worker",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "name",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
  ]

  return (
    <TableLayout
      table={table}
      hasCollapsible={false}
      hideColumnReordering={true}
      selectedData={selectedData}
      setColumnOrder={setColumnOrder}
      buttons={buttons}
      setColumnFilters={setColumnFilters}
      filterResetKey={filterResetKey}
      setFilterResetKey={setFilterResetKey}
      columnNames={columnNames}
      columnConfig={columnConfig}
    />
  )
}
