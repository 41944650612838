import React from "react"
import {useDataTable} from "@components/data-grid/hooks/useDataTable"
import TableLayout from "@components/tables/components/table-layout"
import {getColNames} from "@components/tables/utils/getColNames"
import {DataTableProps} from "@components/data-grid/table.types"

export function DataTable<TData, TValue>({columns, data, buttons}: DataTableProps<TData, TValue>) {
  const {table, selectedData, setColumnOrder, setColumnFilters, columnFilters, filterResetKey, setFilterResetKey} = useDataTable({
    columns,
    data,
  })

  const columnNames = getColNames(columns)

  const columnConfig = [
    {
      name: "contact_person",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "country",
      multiSelect: true,
      searchable: false,
      showIcon: true,
    },
    {
      name: "ic_number",
      multiSelect: true,
      searchable: true,
      showIcon: true,
    },
    {
      name: "email",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "company_name",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "phone_number",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "city",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
    {
      name: "gdpr_consent",
      multiSelect: false,
      searchable: false,
      showIcon: true,
    },
    {
      name: "newsletter_consent",
      multiSelect: false,
      searchable: false,
      showIcon: true,
    },
    {
      name: "id",
      multiSelect: false,
      searchable: true,
      showIcon: false,
    },
  ]

  return (
    <TableLayout
      table={table}
      buttons={buttons}
      hasCollapsible={true}
      selectedData={selectedData}
      setColumnOrder={setColumnOrder}
      columnNames={columnNames}
      setColumnFilters={setColumnFilters}
      columnFilters={columnFilters}
      filterResetKey={filterResetKey}
      setFilterResetKey={setFilterResetKey}
      columnConfig={columnConfig}
    />
  )
}
