import React from "react"
import {ColumnHeader} from "@components/data-grid/column-header-components/column-header"

import {createColumnHelper} from "@tanstack/react-table"

export type TPerson = {
  url: string
  name: string
  pin: string
  parent: string
  type: string
  tariff: string
  currency: string
  turnover: {
    current: string
    before: string
  }
}

export type THeaders = {
  name: string
  pin: string
  parent: string
  type: string
  tariff: string
  currency: string
  turnover: {
    current: string
    before: string
  }
}

const columnHelper = createColumnHelper<TPerson>()

export const getColumns = (headers: THeaders) => {
  return [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      header: ({column}) => <ColumnHeader title={headers.name} column={column} />,
      cell: (props) => <a href={props.row.original.url}>{props.getValue()}</a>,
      size: 200,
    }),
    columnHelper.accessor((row) => row.pin, {
      id: "pin",
      header: ({column}) => <ColumnHeader title={headers.pin} column={column} />,
    }),
    columnHelper.accessor((row) => row.parent, {
      id: "parent",
      header: ({column}) => <ColumnHeader title={headers.parent} column={column} />,
    }),
    columnHelper.accessor((row) => row.type, {
      id: "type",
      header: ({column}) => <ColumnHeader title={headers.type} column={column} />,
    }),
    columnHelper.accessor((row) => row.tariff, {
      id: "tariff",
      header: ({column}) => <ColumnHeader title={headers.tariff} column={column} />,
    }),
    columnHelper.accessor((row) => row.currency, {
      id: "currency",
      header: ({column}) => <ColumnHeader title={headers.currency} column={column} />,
    }),
    columnHelper.accessor((row) => row.turnover.current, {
      id: "turnoverCurrent",
      header: ({column}) => <ColumnHeader title={headers.turnover.current} column={column} />,
    }),
    columnHelper.accessor((row) => row.turnover.before, {
      id: "turnoverBefore",
      header: ({column}) => <ColumnHeader title={headers.turnover.before} column={column} />,
    }),
  ]
}
